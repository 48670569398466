export const steps = [
    { // 1 -- > 1
        target: "body",
        content: "Vous êtes sur la page des commandes en attente, où vous pouvez suivre l'état de vos commandes.",
        placement: "center" as const,
    },
    { // 2 -- > 2
        target: ".second-step",
        content: "Utilisez la barre de recherche pour trouver rapidement une commande par patient ou praticien.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    { // 3 -- > 3
        target: ".third-step",
        content: "Voici le tableau récapitulatif de toutes vos commandes en attente.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    { // 4  -- > 4
        target: ".tr-step",
        content: "Chaque commande est identifiée par son numéro unique, avec le nom complet du patient et du praticien. La pastille indique son statut actuel.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    { // 8 -- > 5
        target: ".invalidPastille-step",
        content: "Si une commande est refusée par Lio, découvrez pourquoi ici.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    { // 9 -- > 6
        target: ".invaliding-step",
        content: "Cliquez sur ce bouton pour voir les détails de la commande.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    { // 11 -- > 7
        target: ".background-step",
        content: "Dans cette fenêtre modale, consultez et éditez toutes les informations de la commande.",
        placementBeacon: "left" as const,
        placement: "center" as const,
    },
    { // 12 -- > 8
        target: ".messageError-step",
        content: "Si une commande est refusée, consultez le message d'erreur ici.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    { // 13 -- > 9
        target: ".allBlock-step",
        content: "Comme lors de la création de la commande, ajoutez des commentaires ou des fichiers si nécessaire.",
        placementBeacon: "left" as const,
        placement: "auto" as const,
    },
    { // 16 -- > 12
        target: ".contentOrderError-step",
        content: "Consultez et modifiez le contenu de la commande ici.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    { // 17 -- > 13
        target: ".cancelOrder-step",
        content: "Pour annuler la commande, cliquez ici.",
        placementBeacon: "left" as const,
        placement: "top" as const,
    },
    { // 18 -- > 14
        target: ".modifOrder-step",
        content: "Une fois les modifications apportées, validez la commande en cliquant ici pour la renvoyer en attente.",
        placementBeacon: "left" as const,
        placement: "top" as const,
    },
    {
        target: ".background-step",
        content: "Vous avez désormais exploré toutes les fonctionnalités relatives aux commandes en attente.",
        placementBeacon: "left" as const,
        placement: "center" as const,
    },
    { // 19 -- > 15
        target: ".in_progress_step",
        content: "Cliquez ici pour passer à l'étape suivante.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
];


export const facticeOrder = [
    {
        id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        code: "TEST-0001",
        patientId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        patientFullName: "Jonh Doe",
        practicerId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        practicerFullName: "Dr. Doc",
        companyId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        companyName: "Cabient de Doc",
        assignedLabId: null,
        assignedEmployeeId: null,
        deliveryTarget: "2024-02-22T05:00:00.000Z",
        modelVerified: false,
        canceled: false,
        products: [],
        statuses: [
            {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                orderId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                information: "",
                status: "CREATED",
                date: "2024-02-03T05:00:00.000Z",
                employeeId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                employeeFullName: "Modélisateur X",
                accountEmployeeFullName: "Modélisateur X",
                comment: "Création de la commande"
            }
        ],
        step: "WAITING",
        comment: "Merci de porter une attention particulière à la forme des dents ainsi qu'à leur inclinaison.",
        createdAt: "2024-02-03T05:00:00.000Z",
        updatedAt: "2024-02-03T05:00:00.000Z",
        travelVoucher: null
    },
    {
        id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        code: "TEST-0002",
        patientId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        patientFullName: "Jonh Doe",
        practicerId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        practicerFullName: "Dr. Doc",
        companyId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        companyName: "Cabient de Doc",
        assignedLabId: null,
        assignedEmployeeId: null,
        deliveryTarget: "2024-02-22T05:00:00.000Z",
        modelVerified: false,
        canceled: false,
        products: [{
            id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
            orderId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
            canceled: false,
            productRef: {
                value: "3 implants",
                code: "MOD-CG-3i",
                price: 29.95,
                description: "Modélisation d'un guide chirurgical - 3 implants"
            },
            teeth: [27, 28, 26],
            tint: null,
            quantity: 1,
            materialName: null,
            materialLotId: null,
            createdAt: "2024-02-03T05:00:00.000Z",
        }],
        statuses: [
            {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                orderId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                information: "",
                status: "CREATED",
                date: "2024-02-03T05:00:00.000Z",
                employeeId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                employeeFullName: "Modélisateur X",
                accountEmployeeFullName: "Modélisateur X",
                comment: "Création de la commande"
            },
            {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                orderId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                information: "",
                status: "INVALID",
                date: "2024-02-03T05:00:00.000Z",
                employeeId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                employeeFullName: "Modélisateur X",
                accountEmployeeFullName: "Modélisateur X",
                comment: "Il manque des informations sur les dents, merci de compléter dans la partie commentaire."
            }
        ],
        step: "INVALID",
        comment: "Merci de porter une attention particulière à la forme des dents ainsi qu'à leur inclinaison.",
        createdAt: "2024-02-03T05:00:00.000Z",
        updatedAt: "2024-02-03T05:00:00.000Z",
        travelVoucher: null
    }
]
