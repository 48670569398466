import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { RouteData, routes } from "./services/routes";

import "./styles/index.css";

import { Storage } from "./services/storage";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
library.add(fas);

const token = Storage.getToken()
const roles = Storage.getRoles()


function renderRoutes(routes: RouteData[]) {
    return routes.map((route, index) => {
        let element = null;
        if (route.auth.length === 0 || route.auth.some((auth) => roles.includes(auth))) {
            element = route.element;
        } else {
            element = <Navigate to="/dash" />;
        }

        if (!token && route.path !== "/" && route.path !== "/register" && route.path !== "/ask-reset" && route.path !== "/reset-password/:id" && route.path !== "/auth/:id") {
            element = <Navigate to="/" />;
        }

        return (
            <Route
                key={route.path}
                path={route.path}
                element={element}
                children={route.routes ? renderRoutes(route.routes || []) : null}
            />
        );
    });
}

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                {routes.map((routeGroup, index) => (
                    <Route
                        key={index}
                        path={routeGroup.baseUrl || '/'}
                        element={routeGroup.element || null}
                        children={renderRoutes(routeGroup.pages)}
                    />
                ))}
            </Routes>
        </BrowserRouter>
        <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="light"
        />
    </React.StrictMode>
);
