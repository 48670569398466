import APIClient from "../APIClients";

export default class PraticerAPI {
    public static async newUser(data: {}) {
        return APIClient.client.post("/company/practicer", data);
    }

    public static async deleteUser(id: string) {
        return APIClient.client.delete(`/company/practicer?id=${id}`);
    }

    public static async users() {
        return APIClient.client.get(`/company/practicer/all`);
    }

    public static async getPraticers() {
        return APIClient.client.get(`/company/practicer/all`);
    }

    public static async getPraticer(id: string) {
        return APIClient.client.get(`/company/practicer?id=${id}`);
    }

    public static async getUser(id: string | null) {
        return APIClient.client.get(`/company/practicer${id !== "" && `?id=${id}`}`);
    }

    public static async putUser(id: string | null, data:{}) {
        return APIClient.client.put(`/company/practicer${id !== "" && `?id=${id}`}`, data);
    }
}
