import { Invoice } from "../dto/invoice.dto";
import InvoiceListElement from "./Element";

export interface MonthInvoices {
    month: string;
    invoices: Invoice[];
}

interface InvoiceListMonthProps {
    month: MonthInvoices;
    onSelect: (id: string) => void;
    onUnselect: (id: string) => void;
    selectedInvoices: string[];
    onDelete?: (id: string) => void;
    onValidate?: (id: string) => void;
    editable?: boolean;
}

export default function InvoiceListMonth({ month, onSelect, onUnselect, selectedInvoices, onDelete, onValidate, editable }: Readonly<InvoiceListMonthProps>) {

    const isSelected = (id: string) => {
        return selectedInvoices.includes(id)
    }

    return (
        <div>
            <div className="flex justify-between items-center w-full py-4 border-b border-b-blue-gray-100 antialiased font-sans text-xl text-left font-semibold leading-snug select-none hover:text-blue-gray-900 transition-colors text-blue-gray-900">
                {month.month}
            </div>
            <div className="grid grid-cols-1 gap-4 py-5 overflow-x-auto">
                {month.invoices.map(e => <InvoiceListElement
                    key={e.id}
                    invoice={e}
                    onSelect={() => onSelect(e.id)}
                    onDeselect={() => onUnselect(e.id)}
                    selected={isSelected(e.id)}
                    onDelete={onDelete}
                    onValidate={onValidate}
                    editable={editable}
                />)}
            </div>
        </div>
    )
}