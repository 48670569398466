import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner, Typography } from "@material-tailwind/react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import OrderAPI from "../services/API/clients/OrderAPI";

export default function PictureDropDown({
    orderId,
    fetchFiles,
    id,
}: Readonly<{ orderId: string; fetchFiles: () => void; id: string }>) {
    const [loading, setLoading] = useState(false);

    const onDrop = async (acceptedFiles: any) => {
        if (loading) return;
        if (acceptedFiles && acceptedFiles.length > 0) {
            setLoading(true);
            const datas = new FormData();
            acceptedFiles.forEach((file: any) => {
                datas.append("files", file);
            });
            datas.append("orderId", orderId);

            const response = await OrderAPI.uploadFiles(datas);
            if (response.status === 201) {
                toast.success("Les fichiers ont bien été uploadés");
                fetchFiles();
                setLoading(false);
            } else if (response.status === 400) {
                toast.warning("Veuillez vérifier que tous les champs ont bien été complété");
                setLoading(false);
            } else if (response.status === 409) {
                toast.error("Ces fichiers existent déjà");
                setLoading(false);
            } else if (response.status === 413) {
                toast.error("Le fichier est trop volumineux");
                setLoading(false);
            } else {
                toast.error("Une erreur est survenue lors de l'upload des fichiers");
                setLoading(false);
            }
        }
    };

    const handleFilesChange = async (event: any) => {
        if (event.target.files.length === 0) return;
        if (loading) return;
        setLoading(true);
        const newFiles = Array.from(event.target.files);
        if (newFiles.length === 0) return;
        const datas = new FormData();
        newFiles.forEach((file: any) => {
            datas.append("files", file);
        });
        datas.append("orderId", orderId);

        const response = await OrderAPI.uploadFiles(datas);
        if (response.status === 201) {
            toast.success("Les fichiers ont bien été uploadés");
            fetchFiles();
            setLoading(false);
        } else if (response.status === 400) {
            toast.warning("Veuillez vérifier que tous les champs ont bien été complété");
            setLoading(false);
        } else if (response.status === 409) {
            toast.error("Ces fichiers existent déjà");
            setLoading(false);
        } else if (response.status === 413) {
            toast.error("Le fichier est trop volumineux");
            setLoading(false);
        } else {
            toast.error("Une erreur est survenue lors de l'upload des fichiers");
            setLoading(false);
        }
    };

    const { getRootProps, isDragActive } = useDropzone({ onDrop, noClick: true, noKeyboard: true });

    return (
        <label
            htmlFor={id}
            className="relative flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            {...getRootProps()}
        >
            {isDragActive && (
                <div className="flex flex-col items-center justify-center absolute top-0 left-0 w-full h-full bg-gray-300/50 z-50">
                    <FontAwesomeIcon icon="cloud" />
                    <Typography variant="h5" className="text-gray-900">
                        Déposez vos fichiers ici
                    </Typography>
                </div>
            )}
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <svg className="w-7 h-7 mb-1 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            Fichiers <span className="font-semibold">clients</span>
                        </p>
                    </>
                )}
            </div>
            <input id={id} type="file" className="hidden" onChange={handleFilesChange} multiple />
        </label>
    );
}
