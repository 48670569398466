import { Input, Spinner } from "@material-tailwind/react";
import { useState } from "react";
import { ReactConfirmAlertProps, confirmAlert } from "react-confirm-alert";

export default function PromptPopup({ color, colorHover, text, recupComment, title, buttonText, isLoading } : Readonly<{ color: string, colorHover: string, text: string, recupComment: Function, title: string, buttonText: string, isLoading: boolean }>) {
    function GroupTitle({ onGroup, onClose }: Readonly<{ onGroup: (title: string, onSuccess: () => void) => void, onClose: () => void }>) {
        const [inputValue, setInputValue] = useState('');

        const handleSubmit = () => {
            onGroup(inputValue, onClose);
        };

        return (
            <div className="mt-5">
                <Input
                    color="deep-purple"
                    type="text"
                    label="Commentaire"
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                />
                <div className="react-confirm-alert-button-group">
                    <button onClick={handleSubmit}>{buttonText}</button>
                    <button className="lio-alert-cancel" onClick={onClose}>Annuler</button>
                </div>
            </div>
        );
    }

    const groupPrompt = (onGroup: (title: string, onSuccess: () => void) => void): ReactConfirmAlertProps => ({
        customUI: ({ onClose }) => {
            return (
                <div className="react-confirm-alert-body">
                    <h1>{title}</h1>
                    <GroupTitle onGroup={onGroup} onClose={onClose} />
                </div>
            );
        },
        buttons: [
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [],
        overlayClassName: "lio-alert-overlay",
    })

    const needComment = () => {
        confirmAlert(groupPrompt((title: string, onSuccess: () => void) => {
            recupComment(title, true);
            onSuccess();
        }))

    }

    return (
        <button
            type="button"
            onClick={() => needComment()}
            className={`inline-flex w-full justify-center rounded-md bg-${color} px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${colorHover} sm:ml-3 sm:w-auto`}
        >
            {isLoading 
                ? <Spinner color="gray" />
                : text
            }
        </button>
    );
}