import React from "react";
import { Outlet } from "react-router-dom";

import Aside from "./Aside";
import Top from "./Top";

export default function Dash() {
    const [open, setOpen] = React.useState(true);
    const [openModal, setOpenModal] = React.useState(false);

    const handleOpen = () => {
        setOpen(!open);
    };

    const handleOpenModal = () => {
        setOpenModal(!openModal);
    }

    return (
        <div className="h-screen bg-blue-gray-50/50 overflow-x-hidden">
            <div className="p-4 xl:ml-80">
                <Top open={open} handleOpen={handleOpen} />
                <Aside open={open} handleOpen={handleOpen} />
                <Outlet />
            </div>
        </div>
    );
}
