import APIClient from "../APIClients";

export default class FacturationApi {
    public static async findInvoice() {
        const path = '/accounting/invoices'
        return APIClient.client.get(`${path}`);
    }

    public static async getInvoiceDocument(id: string) {
        const path = `/accounting/document/${id}`
        return APIClient.client.get(path);
    }

    public static async getPayment(data: {}) {
        const path = `/payment`
        return APIClient.client.post(path, data);
    }
}
