import axios, { AxiosInstance, AxiosResponse } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { Storage } from "../storage";
import { toast } from "react-toastify";
import { refreshAuthLogic } from "./RefreshLogic";
import FileDownload from "js-file-download";

export enum CLIENT_TYPE {
    COLLABORATOR,
    CLIENT,
    NO_INTERCEPTOR
}

const COLLABORATOR = process.env.REACT_APP_API;
const CLIENT = process.env.REACT_APP_APICLIENT;

const collaboratorClient = axios.create({ baseURL: COLLABORATOR });
const clientClient = axios.create({ baseURL: CLIENT });
const noInterceptor = axios.create({ baseURL: CLIENT });

interface APIResponse {
    status: number;
    body: any;
}

function concatenatePath(base: string, path: string) {
    if (base.endsWith("/") && path.startsWith("/")) return base + path.substring(1);
    if (!base.endsWith("/") && !path.startsWith("/")) return base + "/" + path;
    return base + path;
}

createAuthRefreshInterceptor(collaboratorClient, refreshAuthLogic(concatenatePath(COLLABORATOR ?? "", '/auth/refresh')));
createAuthRefreshInterceptor(clientClient, refreshAuthLogic(concatenatePath(CLIENT ?? "", '/auth/refresh')));

export default class APIBase {
    private client: AxiosInstance;
    private auth: boolean;

    constructor(type: CLIENT_TYPE) {
        this.auth = type !== CLIENT_TYPE.NO_INTERCEPTOR;
        switch (type) {
            case CLIENT_TYPE.COLLABORATOR:
                this.client = collaboratorClient;
                this.client.defaults.maxRedirects = 1;
                this.auth = true;
                break;
            case CLIENT_TYPE.CLIENT:
                this.client = clientClient;
                this.client.defaults.maxRedirects = 1;
                this.auth = true;
                break;
            case CLIENT_TYPE.NO_INTERCEPTOR:
                this.client = noInterceptor;
                this.auth = false;
                break;
        }
    }

    private async tryRequest(req: Promise<AxiosResponse<any, any>>): Promise<APIResponse> {
        try {
            const result = await req;
            return {
                status: result.status,
                body: result.data,
            };
        } catch (e: any) {
            if (this.isRefreshTokenError(e)) {
                window.location.href = "/";
                return { status: 401, body: e };
            } else {
                if (!e.response) {
                    toast.error("Une erreur est survenue, veuillez réessayer plus tard");
                    return {
                        status: e.status,
                        body: {}
                    };
                }
                return {
                    status: e.status,
                    body: e.response.data,
                };
            }
        }
    }

    private isRefreshTokenError(e: any) {
        if (e.status !== 401) return false;
        const path = e.request?.responseURL;
        if (!path) return false;
        const pathName = new URL(path).pathname;
        return pathName === "/refresh";
    }

    private async getAuthHeader() {
        if (!this.auth) return {};
        const token = Storage.getToken();
        if (!token) return {};
        return { Authorization: `Bearer ${token}` };
    }

    async get(path: string) {
        const headers = await this.getAuthHeader();
        return this.tryRequest(this.client.get(path, { headers }));
    }

    async getRedirect(path: string) {
        const headers: any = await this.getAuthHeader();
        headers["Content-Type"] = "text/plain";
        return this.tryRequest(this.client.get(path, { headers, maxRedirects: 42 }));
    }

    async getAndDownload(path: string, asName: string) {
        const headers = await this.getAuthHeader();
        const content = await this.tryRequest(this.client.get(path, { headers, responseType: 'blob' }));
        if (content.status === 200)
            FileDownload(content.body, asName)
        return content;
    }

    async getBlob(path: string) {
        const headers = await this.getAuthHeader();
        const content = await this.tryRequest(this.client.get(path, { headers, responseType: 'blob' }));
        return content;
    }

    async getArgs(path: string, data: any) {
        const headers = await this.getAuthHeader();
        return this.tryRequest(this.client.get(path, { headers: headers, data: data }));
    }

    async delete(path: string) {
        const headers = await this.getAuthHeader();
        return this.tryRequest(this.client.delete(path, { headers }));
    }

    async post(path: string, data: any) {
        const headers: any = await this.getAuthHeader();
        headers["Content-Type"] = "application/json";
        return this.tryRequest(this.client.post(path, data, { headers }));
    }

    async patch(path: string, data: any) {
        const headers: any = await this.getAuthHeader();
        headers["Content-Type"] = "application/json";
        return this.tryRequest(this.client.patch(path, data, { headers }));
    }

    async put(path: string, data: any) {
        const headers: any = await this.getAuthHeader();
        headers["Content-Type"] = "application/json";
        return this.tryRequest(this.client.put(path, data, { headers }));
    }

    async postPicture(path: string, data: any) {
        const headers: any = await this.getAuthHeader();
        headers["Content-Type"] = "multipart/form-data";
        return this.tryRequest(this.client.post(path, data, { headers }));
    }

    async putPicture(path: string, data: any) {
        const headers: any = await this.getAuthHeader();
        headers["Content-Type"] = "multipart/form-data";
        return this.tryRequest(this.client.put(path, data, { headers }));
    }
}
