export const steps = [
    { // 1
        target: "body",
        content: "Ici, vous pouvez créer, modifier ou supprimer des patients.",
        placement: "center" as const,
    },
    { // 2
        target: ".second-step",
        content: "Utilisez la barre de recherche pour trouver rapidement un patient par son prénom ou son nom.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    { // 3 + 4 + 5 + 6
        target: ".third-step",
        content: "Voici le tableau récapitulatif de vos patients, comprenant leur prénom, nom et civilité.",
        placementBeacon: "left" as const,
        placement: "auto" as const,
    },
    { // 7 -> 4
        target: ".openModalNew-step",
        content: "Ce bouton ouvre un formulaire pour créer un nouveau patient.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    { // 9 -> 6
        target: ".whereAmIForIt-step",
        content: "Après avoir rempli le formulaire, cliquez ici pour créer le patient.",
        placementBeacon: "left" as const,
        placement: "center" as const,
    },
    { // 10 -> 7
        target: ".editPatient-step",
        content: "Pour modifier un patient, cliquez ici. Le formulaire s'ouvrira pour effectuer les modifications.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    { // 11 -> 8
        target: ".whereAmIForIt-step",
        content: "Cliquez sur le bouton 'Supprimer' pour supprimer un patient, et pour valider les modifications, cliquez sur le bouton 'Modifier'.",
        placementBeacon: "left-start" as const,
        placement: "center" as const,
    },
    { // 14 -> 11
        target: ".facturation_step",
        content: "Cliquez ici pour passer à l'étape suivante.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
];


export const facticeOrder = [
    {
        id: "1",
        firstname: "Jean",
        lastname: "Dupont",
        civility: "Monsieur",
    },
    {
        id: "2",
        firstname: "Jeanne",
        lastname: "Laurent",
        civility: "Madame",
    },
    {
        id: "3",
        firstname: "Jean",
        lastname: "Xavier",
        civility: "Autre",
    }
]
