import APIClient from "../APIClients";

export default class MessagerieAPI {
    public static async newMessage(data: {}) {
        return APIClient.client.post("/order/Messaging/respond", data);
    }

    public static async newMessageWithPicture(data: {}) {
        return APIClient.client.postPicture("/order/Messaging/respond", data);
    }

    public static async message(id: string) {
        return APIClient.client.get(`/order/Messaging/find${id !== "" ? `?orderId=${id}`: ""}`);
    }

    public static async messages() {
        return APIClient.client.get(`/order/Messaging/find`);
    }
}
