import { Spinner } from "@material-tailwind/react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import AuthAPI from "../../services/API/clients/AuthAPI";
import { Storage } from "../../services/storage";

export default function Auth() {
    const currentId = useParams().id;

    useEffect(() => {
        const fetchData = async () => {
            if (!currentId || currentId === undefined) return;
            const response = await AuthAPI.autoLogin(currentId);
            if (response.status === 200) {
                Storage.setToken(response.body.access_token);
                Storage.setRefreshToken(response.body.refresh_token);
                Storage.setRoles(response.body.roles)
                Storage.setIsLogAs("true")
                const respMe = await AuthAPI.me();
                if (respMe.status === 200) {
                    Storage.setAccountId(respMe.body.accountId);
                    Storage.setPracticerId(respMe.body.practicerId);
                    Storage.setCompanyId(respMe.body.companyId);
                    Storage.setContactId(respMe.body.contactId);
                    Storage.setTutorialId(respMe.body.tutorialId);
                    window.location.href = "/dash/order";
                }
            }
        }
        fetchData()
    }, [currentId])

    return (
        <div className="h-screen w-screen flex justify-center items-center">
            <Spinner color="deep-purple" className="w-12 h-12" />
        </div>
    )
}