import React, { useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { Input } from "@material-tailwind/react";

import PraticerAPI from "../../services/API/clients/PraticerAPI";

export default function EmployeeModal({
    setOpen,
    data,
    handleStatus,
}: Readonly<{ setOpen: (open: string, arg0?: boolean) => void; data: any; handleStatus: any }>) {
    const [praticer, setPraticer] = React.useState({
        id: "",
        name: "",
        price: "",
        providerProductRef: "",
    });

    useEffect(() => {
        setPraticer({
            id: data.id,
            name: data.name,
            price: data.price,
            providerProductRef: data.providerProductRef,
        });
    }, [data]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = {
            name: praticer.name,
            price: Number(praticer.price),
            providerProductRef: praticer.providerProductRef,
        };

        const resp = await PraticerAPI.putUser(praticer.id, data);
        if (resp.status === 200) {
            handleStatus("success", "Le produit a bien été modifié");
            setOpen("produitEdit", true);
        } else if (resp.status === 409) {
            handleStatus("already", "Ce produit existe déjà");
        } else if (resp.status === 400) {
            handleStatus("complete", "Veuillez vérifier que tous les champs ont bien été complété");
        } else {
            handleStatus("error", "Une erreur est survenue lors de la modification de l'employé");
        }
    };

    const handleDelete = async () => {
        const resp = await PraticerAPI.deleteUser(praticer.id);
        if (resp.status === 200) {
            handleStatus("success", "L'employé a bien été supprimé");
            setOpen("produitEdit", true);
        }
    };

    const handleInputChange = (event: { target: { name: any; value: any } }) => {
        const { name, value } = event.target;
        setPraticer({ ...praticer, [name]: value });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 overflow-y-auto">
                <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 border-b pb-2">
                            Modification de {praticer.name}
                        </Dialog.Title>
                        <div className="flex flex-row flex-wrap justify-center">
                            {[
                                { type: "text", size: "w-1/2", name: "Nom", id: "name", value: "name" },
                                { type: "number", size: "w-1/2", name: "Prix", id: "price", value: "price" },
                                { type: "text", size: "w-full", name: "Référence produit", id: "providerProductRef", value: "providerProductRef" },
                            ].map((input, index: number) => (
                                <div className={`p-2 flex flex-col justify-center ${input.size}`} key={input.name}>
                                    <Input
                                        type={input.type}
                                        color="deep-purple"
                                        name={input.id}
                                        id={input.id}
                                        onChange={handleInputChange}
                                        label={input.name}
                                        value={praticer[input.value as keyof typeof praticer]}
                                        required
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                    type="button"
                    onClick={() => handleDelete()}
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                    Supprimer
                </button>
                <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                >
                    Modifier
                </button>
                <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen("produitEdit")}
                >
                    Fermer
                </button>
            </div>
        </form>
    );
}
