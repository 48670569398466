export const Headers = [
    {
        id: "Prénom",
        col: "firstname",
    },
    {
        id: "Nom",
        col: "lastname",
    },
    {
        id: "Adresse mail",
        col: "email",
    },
    {
        id: "Profession",
        col: "profession",
    },
    {
        id: "Téléphone",
        col: "phone",
    },
    {
        id: "Téléphone mobile",
        col: "mobilePhone",
    },
];
