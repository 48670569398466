import APIClient from "../APIClients";

export default class TutorialsAPI {
    public static async getTutorials() {
        return APIClient.client.get(`/tutorial`);
    }

    public static async seeTutorial(data: {}) {
        return APIClient.client.put(`/tutorial`, data);
    }
}
