import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "@material-tailwind/react";
import React from "react";
import { useParams } from "react-router-dom";
import Auth from "../../services/API/clients/AuthAPI";
import { toast } from "react-toastify";
import PasswordStrengthBar from "react-password-strength-bar";

export default function ResetPassword() {
    const { id } = useParams();
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState({
        password: "",
        passwordConfirm: "",
    });

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        if (data.password !== data.passwordConfirm) {
            toast.warning("Les mots de passe ne correspondent pas");
            return;
        }

        if (data.password.length < 8) {
            toast.warning("Le mot de passe doit faire au moins 8 caractères");
            return;
        } else if (data.password.length > 32) {
            toast.warning("Le mot de passe ne doit pas dépasser 32 caractères");
            return;
        } // le mot de passe doit contenir au moins un chiffre
        else if (!/\d/.test(data.password)) {
            toast.warning("Le mot de passe doit contenir au moins un chiffre");
            return;
        } // le mot de passe doit contenir au moins une lettre
        else if (!/[a-zA-Z]/.test(data.password)) {
            toast.warning("Le mot de passe doit contenir au moins une lettre");
            return;
        } else if (!/[^a-zA-Z0-9]/.test(data.password)) {
            toast.warning("Le mot de passe doit contenir au moins un caractère spécial");
            return;
        }

        if (id === undefined) return;

        const datas = {
            value: data.password,
        };

        const response = await Auth.resetPassword(id, datas);
        if (response.status === 200 || response.status === 201) {
            window.location.href = "/";
        } else {
            toast.error("Une erreur est survenue");
        }

        setLoading(false);
    };

    const handleInputChange = (event: { target: { name: any; value: any } }) => {
        setData({ ...data, [event.target.name]: event.target.value });
    };

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center overflow-hidden">
            <div className="relative py-3 mx-auto w-full md:w-1/2">
                <div className="absolute inset-0 bg-gradient-to-r from-capucine-700 to-capucine-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                <div className="relative px-2 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-14 mx-auto">
                    <h1 className="text-2xl text-royal-900 font-semibold">Espace Client</h1>
                    <form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
                        <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                            <div className="relative">
                                <div className="mb-2">
                                    <Input
                                        autoComplete="off"
                                        id="password"
                                        name="password"
                                        type="password"
                                        variant="standard"
                                        color="deep-purple"
                                        label="Mot de passe"
                                        value={data.password}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <Input
                                    autoComplete="off"
                                    id="passwordConfirm"
                                    name="passwordConfirm"
                                    type="password"
                                    variant="standard"
                                    color="deep-purple"
                                    label="Confirmer le mot de passe"
                                    value={data.passwordConfirm}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="w-full">
                                    <PasswordStrengthBar password={data.password} />
                                </div>
                            </div>
                            <div className="relative">
                                <button className="bg-royal-900 hover:bg-royal-800 text-white rounded-md px-2 py-1">
                                    {loading && <FontAwesomeIcon icon="spinner" className="animate-spin" />} Changer le mot de passe
                                </button>

                                <a href="/register" className="block text-right text-sm">
                                    Vous n'avez pas de compte ? <b>Inscrivez-vous</b>
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
