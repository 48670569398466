import { Badge, Chip, IconButton, Navbar } from "@material-tailwind/react";
import { Bars3Icon, ArrowRightOnRectangleIcon, ChatBubbleLeftIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import { Storage } from "../../services/storage";
import NotificationAPI from "../../services/API/clients/NotificationAPI";
import React, { useEffect, useState } from "react";

interface TopProps {
    open: boolean;
    handleOpen: () => void;
}

export default function Top({ open, handleOpen }: Readonly<TopProps>): React.JSX.Element {
    const [isNotify, setIsNotify] = useState(false);
    const currentUrl = window.location.pathname;
    const isLogAs = Storage.getIsLogAs()

    const fetchData = async () => {
        const res = await NotificationAPI.getNotifs();
        if (res.status === 200) {
            const hasChatType = res.body.some((item: any) => item.type === "MESSAGING");
            if (hasChatType)
                setIsNotify(true);
            else 
                setIsNotify(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentUrl]);


    const handleAccessSettings = () => {
        window.location.href = "/dash/settings";
    };

    const handleAccessChat = () => {
        window.location.href = "/dash/chat";
    };

    const doIPlayIt = () => {
        return "static"
        // return "sticky"
    }

    const handleLogoutLogAs = async () => {
        Storage.emptyStorage()
        // close current tab
        window.close()
    }

    return (
        <Navbar color="white" className={`rounded-xl transition-all top-4 z-30 py-3 shadow-md shadow-indigo-gray-500/5 ${doIPlayIt()}`} fullWidth blurred>
            <div className="flex justify-between flex-row md:items-center">
                <div className="capitalize flex items-center">
                    <IconButton variant="text" color="red" className={`grid xl:hidden`} onClick={() => handleOpen()}>
                        <Bars3Icon strokeWidth={3} className="h-6 w-6 text-capucine-700" />
                    </IconButton>
                    <h6 className="text-royal-900 font-bold flex">
                        {isLogAs === "true" && (
                            <Chip
                                color="lime"
                                value="Log-As"
                                className="mr-2 text-white"
                                onClose={() => handleLogoutLogAs()}
                            />
                        )}
                        {Storage.getRoles().includes("ADMIN")
                            ? "Administrateur"
                            : Storage.getRoles().includes("COMPANY")
                            ? "Compte entreprise"
                            : Storage.getRoles().includes("PRACTICER")
                            ? "Praticien"
                            : "Inconnu"}
                    </h6>
                </div>
                <div className="flex items-center">
                    <IconButton
                        variant="text"
                        color="lime"
                        onClick={() => handleAccessSettings()}
                    >
                        <Cog6ToothIcon className="h-5 w-5 text-capucine-700" />
                    </IconButton>
                    {isNotify ? (
                        <IconButton
                            variant="text"
                            color="lime"
                            onClick={() => handleAccessChat()}
                        >
                            <Badge className="animate-pulse mt-1" color="lime" withBorder>
                                <ChatBubbleLeftIcon className="h-5 w-5 text-capucine-700 mt-1" />
                            </Badge>
                        </IconButton>
                    ) : (
                        <IconButton
                            variant="text"
                            color="lime"
                            onClick={() => handleAccessChat()}
                        >
                            <ChatBubbleLeftIcon className="h-5 w-5 text-capucine-700" />
                        </IconButton>
                    )}
                    <IconButton
                        variant="text"
                        color="red"
                        onClick={() => {
                            localStorage.clear();
                            window.location.href = "/";
                        }}
                    >
                        <ArrowRightOnRectangleIcon className="h-5 w-5 text-capucine-700" />
                    </IconButton>
                </div>
            </div>
        </Navbar>
    );
}
