import { toast } from "react-toastify";

export class Storage {
    private static set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    private static get(key: string) {
        try {
            const value = localStorage.getItem(key);
            if (value) return JSON.parse(value);
            return null;
        } catch (error) {
            toast.error("Une erreur est survenue lors de la récupération de vos données")
            Storage.emptyStorage();
            window.location.href = "/";
            return null;
        }
    }

    static setRoles(roles: string[]) {
        this.set("roles", roles);
    }

    static getRoles(): string[] {
        return this.get("roles") || [];
    }

    static setToken(token: string) {
        this.set("token", token);
    }

    static getToken() : string | null {
        return this.get("token");
    }

    static setRefreshToken(token: string) {
        this.set("refreshToken", token);
    }

    static getRefreshToken() : string | null {
        return this.get("refreshToken");
    }

    static setPracticerId(token: string) {
        this.set("practicerId", token);
    }

    static getPracticerId() : string | null {
        return this.get("practicerId");
    }

    static setAccountId(token: string) {
        this.set("accountId", token);
    }

    static getAccountId() : string | null {
        return this.get("accountId");
    }

    static setTutorialId(token: string) {
        this.set("tutorialId", token);
    }

    static getTutorialId() : string | null {
        return this.get("tutorialId");
    }

    static setContactId(token: string) {
        this.set("contactId", token);
    }

    static getContactId() : string | null {
        return this.get("contactId");
    }

    static setCompanyId(token: string) {
        this.set("companyId", token);
    }

    static getCompanyId() : string | null {
        return this.get("companyId");
    }

    static setIsLogAs(token: string) {
        this.set("isLogAs", token);
    }

    static getIsLogAs() : string | null {
        return this.get("isLogAs");
    }
    
    static emptyStorage() {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("roles");
        localStorage.removeItem("practicerId");
        localStorage.removeItem("accountId");
        localStorage.removeItem("tutorialId");
        localStorage.removeItem("contactId");
        localStorage.removeItem("companyId");
        localStorage.removeItem("isLogAs");
    }

    static emptyStorageComplete() {
        localStorage.clear();
    }
}