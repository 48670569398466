export const steps = [
    {
        target: "body",
        content: "Bienvenue dans la section des commandes terminées. Le processus est identique à celui des commandes en cours.",
        placement: "center" as const,
    },
    {
        target: ".canceledPastille-step",
        content: "Pastille d'annulation de commande.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    {
        target: ".sentPastille-step",
        content: "Pastille de commande envoyée.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    {
        target: ".suiviColis-step",
        content: "Après l'envoi de la commande, ce bouton vous redirige vers TNT pour suivre la livraison.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    {
        target: ".otherTutorials-step",
        content: "Vous avez terminé le tutoriel sur la gestion des commandes. Passez aux tutoriels sur la gestion de votre compte.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
];

export const facticeOrder = [
    {
        id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        code: "TEST-0001",
        patientId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        patientFullName: "Jonh Doe",
        practicerId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        practicerFullName: "Dr. Doc",
        companyId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        companyName: "Cabient de Doc",
        assignedLabId: null,
        assignedEmployeeId: null,
        deliveryTarget: "2024-02-22T05:00:00.000Z",
        modelVerified: false,
        canceled: false,
        products: [],
        statuses: [
            {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                orderId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                information: "",
                status: "CREATED",
                date: "2024-02-03T05:00:00.000Z",
                employeeId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                employeeFullName: "Modélisateur X",
                accountEmployeeFullName: "Modélisateur X",
                comment: "Création de la commande"
            }
        ],
        step: "CANCELED",
        comment: "Merci de porter une attention particulière à la forme des dents ainsi qu'à leur inclinaison.",
        createdAt: "2024-02-03T05:00:00.000Z",
        updatedAt: "2024-02-03T05:00:00.000Z",
        travelVoucher: null
    },
    {
        id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        code: "TEST-0002",
        patientId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        patientFullName: "Jonh Doe",
        practicerId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        practicerFullName: "Dr. Doc",
        companyId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        companyName: "Cabient de Doc",
        assignedLabId: null,
        assignedEmployeeId: null,
        deliveryTarget: "2024-02-22T05:00:00.000Z",
        modelVerified: false,
        canceled: false,
        products: [{
            id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
            orderId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
            canceled: false,
            productRef: {
                value: "3 implants",
                code: "MOD-CG-3i",
                price: 29.95,
                description: "Modélisation d'un guide chirurgical - 3 implants"
            },
            teeth: [27, 28, 26],
            tint: null,
            quantity: 1,
            materialName: null,
            materialLotId: null,
            createdAt: "2024-02-03T05:00:00.000Z",
        }],
        statuses: [
            {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                orderId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                information: "",
                status: "CREATED",
                date: "2024-02-03T05:00:00.000Z",
                employeeId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                employeeFullName: "Modélisateur X",
                accountEmployeeFullName: "Modélisateur X",
                comment: "Création de la commande"
            },
            {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                orderId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                information: "",
                status: "INVALID",
                date: "2024-02-03T05:00:00.000Z",
                employeeId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                employeeFullName: "Modélisateur X",
                accountEmployeeFullName: "Modélisateur X",
                comment: "Il manque des informations sur les dents, merci de compléter dans la partie commentaire."
            }
        ],
        step: "SENT",
        comment: "Merci de porter une attention particulière à la forme des dents ainsi qu'à leur inclinaison.",
        createdAt: "2024-02-03T05:00:00.000Z",
        updatedAt: "2024-02-03T05:00:00.000Z",
        travelVoucher: "abcde"
    }
]
