export const steps = [
    {
        target: "body",
        content: "Bienvenue sur la page des commandes en cours. Vous pouvez suivre l'état de chacune de vos commandes ici. Le fonctionnement est similaire à celui des commandes en attente.",
        placement: "center" as const,
    },
    {
        target: ".third-step",
        content: "Les pastilles indiquent l'état actuel de la commande : modélisation, production, finition ou attente d'envoi.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    {
        target: ".clientVerifPastille-step",
        content: "Une pastille spécifique signifie que la commande nécessite votre validation ou votre refus.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    {
        target: ".editPart-step",
        content: "Utilisez ce bouton pour vérifier la commande.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    {
        target: ".background-step",
        content: "En cliquant dessus, la fenêtre de vérification apparaîtra. Voici son contenu :",
        placementBeacon: "left-start" as const,
        placement: "center" as const,
    },
    {
        target: ".sideInfoGenrales-step",
        content: "Sur cette section, retrouvez les informations générales de la commande.",
        placementBeacon: "left" as const,
        placement: "right" as const,
    },
    {
        target: ".filesForInfo-step",
        content: "Consultez ici les fichiers de la commande fournis par Lio.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    {
        target: ".addFiles-step",
        content: "Ajoutez des fichiers supplémentaires à tout moment en cliquant ici.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    {
        target: ".listCommand-step",
        content: "Consultez la liste des produits commandés ici.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    {
        target: ".askModif-step",
        content: "Si des modifications sont nécessaires, signalez-les en cliquant sur ce bouton.",
        placementBeacon: "left" as const,
        placement: "top" as const,
    },
    {
        target: ".orderOk-step",
        content: "Sinon, validez le travail réalisé en cliquant ici. Attention ! Si vous ne faites ni l'un ni l'autre, la commande ne progressera pas !",
        placementBeacon: "left" as const,
        placement: "top" as const,
    },
    {
        target: ".background-step",
        content: "Vous avez exploré toutes les fonctionnalités des commandes en cours.",
        placementBeacon: "left" as const,
        placement: "center" as const,
    },
    {
        target: ".done_step",
        content: "Cliquez ici pour passer à l'étape suivante.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
];

export const facticeOrder = [
    {
        id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        code: "TEST-0001",
        patientId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        patientFullName: "Jonh Doe",
        practicerId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        practicerFullName: "Dr. Doc",
        companyId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        companyName: "Cabient de Doc",
        assignedLabId: null,
        assignedEmployeeId: null,
        deliveryTarget: "2024-02-22T05:00:00.000Z",
        modelVerified: false,
        canceled: false,
        products: [{
            id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
            orderId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
            canceled: false,
            productRef: {
                value: "3 implants",
                code: "MOD-CG-3i",
                price: 29.95,
                description: "Modélisation d'un guide chirurgical - 3 implants"
            },
            teeth: [27, 28, 26],
            tint: null,
            quantity: 1,
            materialName: null,
            materialLotId: null,
            createdAt: "2024-02-03T05:00:00.000Z",
        },{
            id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
            orderId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
            canceled: false,
            productRef: {
                value: "4 implants",
                code: "PROD-W-CP-XE",
                price: 139.95,
                description: "Production d'élément(s) en céramique - Gamme Premium"
            },
            teeth: [32, 31, 41, 42],
            tint: null,
            quantity: 4,
            materialName: null,
            materialLotId: null,
            createdAt: "2024-02-03T05:00:00.000Z",
        }
    ],
        statuses: [
            {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                orderId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                information: "",
                status: "CREATED",
                date: "2024-02-03T05:00:00.000Z",
                employeeId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                employeeFullName: "Modélisateur X",
                accountEmployeeFullName: "Modélisateur X",
                comment: "Création de la commande"
            }
        ],
        step: "CLIENT_VERIFICATION",
        comment: "Merci de porter une attention particulière à la forme des dents ainsi qu'à leur inclinaison.",
        createdAt: "2024-02-03T05:00:00.000Z",
        updatedAt: "2024-02-03T05:00:00.000Z",
        travelVoucher: null
    },
    {
        id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        code: "TEST-0002",
        patientId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        patientFullName: "Jonh Doe",
        practicerId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        practicerFullName: "Dr. Doc",
        companyId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        companyName: "Cabient de Doc",
        assignedLabId: null,
        assignedEmployeeId: null,
        deliveryTarget: "2024-02-22T05:00:00.000Z",
        modelVerified: false,
        canceled: false,
        products: [],
        statuses: [
            {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                orderId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                information: "",
                status: "CREATED",
                date: "2024-02-03T05:00:00.000Z",
                employeeId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                employeeFullName: "Modélisateur X",
                accountEmployeeFullName: "Modélisateur X",
                comment: "Création de la commande"
            },
            {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                orderId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                information: "",
                status: "INVALID",
                date: "2024-02-03T05:00:00.000Z",
                employeeId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                employeeFullName: "Modélisateur X",
                accountEmployeeFullName: "Modélisateur X",
                comment: "Il manque des informations sur les dents, merci de compléter dans la partie commentaire."
            }
        ],
        step: "MODELING",
        comment: "Merci de porter une attention particulière à la forme des dents ainsi qu'à leur inclinaison.",
        createdAt: "2024-02-03T05:00:00.000Z",
        updatedAt: "2024-02-03T05:00:00.000Z",
        travelVoucher: null
    },
    {
        id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        code: "TEST-0003",
        patientId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        patientFullName: "Jonh Doe",
        practicerId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        practicerFullName: "Dr. Doc",
        companyId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        companyName: "Cabient de Doc",
        assignedLabId: null,
        assignedEmployeeId: null,
        deliveryTarget: "2024-02-22T05:00:00.000Z",
        modelVerified: false,
        canceled: false,
        products: [],
        statuses: [
            {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                orderId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                information: "",
                status: "CREATED",
                date: "2024-02-03T05:00:00.000Z",
                employeeId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                employeeFullName: "Modélisateur X",
                accountEmployeeFullName: "Modélisateur X",
                comment: "Création de la commande"
            }
        ],
        step: "PRODUCTION",
        comment: "Merci de porter une attention particulière à la forme des dents ainsi qu'à leur inclinaison.",
        createdAt: "2024-02-03T05:00:00.000Z",
        updatedAt: "2024-02-03T05:00:00.000Z",
        travelVoucher: null
    },
    {
        id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        code: "TEST-0004",
        patientId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        patientFullName: "Jonh Doe",
        practicerId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        practicerFullName: "Dr. Doc",
        companyId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        companyName: "Cabient de Doc",
        assignedLabId: null,
        assignedEmployeeId: null,
        deliveryTarget: "2024-02-22T05:00:00.000Z",
        modelVerified: false,
        canceled: false,
        products: [],
        statuses: [
            {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                orderId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                information: "",
                status: "CREATED",
                date: "2024-02-03T05:00:00.000Z",
                employeeId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                employeeFullName: "Modélisateur X",
                accountEmployeeFullName: "Modélisateur X",
                comment: "Création de la commande"
            }
        ],
        step: "FINISHING",
        comment: "Merci de porter une attention particulière à la forme des dents ainsi qu'à leur inclinaison.",
        createdAt: "2024-02-03T05:00:00.000Z",
        updatedAt: "2024-02-03T05:00:00.000Z",
        travelVoucher: null
    },
    {
        id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        code: "TEST-0005",
        patientId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        patientFullName: "Jonh Doe",
        practicerId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        practicerFullName: "Dr. Doc",
        companyId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        companyName: "Cabient de Doc",
        assignedLabId: null,
        assignedEmployeeId: null,
        deliveryTarget: "2024-02-22T05:00:00.000Z",
        modelVerified: false,
        canceled: false,
        products: [],
        statuses: [
            {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                orderId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                information: "",
                status: "CREATED",
                date: "2024-02-03T05:00:00.000Z",
                employeeId: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                employeeFullName: "Modélisateur X",
                accountEmployeeFullName: "Modélisateur X",
                comment: "Création de la commande"
            }
        ],
        step: "VERIFICATION",
        comment: "Merci de porter une attention particulière à la forme des dents ainsi qu'à leur inclinaison.",
        createdAt: "2024-02-03T05:00:00.000Z",
        updatedAt: "2024-02-03T05:00:00.000Z",
        travelVoucher: null
    },
]
