import React, { useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { Input } from "@material-tailwind/react";
import Patient from "../../services/API/clients/PatientAPI";
import Select from "react-select";

const civilites = [
    { label: "Monsieur", value: "MAN" },
    { label: "Madame", value: "WOMAN" },
    { label: "Autre", value: "OTHER" },
];

export default function PatientModal({
    setOpen,
    data,
    handleStatus,
}: Readonly<{ setOpen: (open: string, arg0?: boolean) => void; data: any; handleStatus: any }>) {
    const [patient, setPatient] = React.useState({
        id: "",
        lastname: "",
        firstname: "",
        civility: "OTHER",
        companyId: "",
    });

    useEffect(() => {
        setPatient({
            id: data.id,
            lastname: data.lastname,
            firstname: data.firstname,
            civility: data.civility,
            companyId: data.companyId,
        });
    }, [data]);

    const handleInputChange = (event: { target: { name: any; value: any } }) => {
        const { name, value } = event.target;
        setPatient({ ...patient, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = {
            lastname: patient.lastname,
            firstname: patient.firstname,
            civility: patient.civility,
            companyId: patient.companyId,
        };
    
        const resp = await Patient.putPatient(patient.id, data);

        if (resp.status === 200) {
            handleStatus("success", "Le patient a bien été modifié");
            setOpen("editpatient", true);
        } else if (resp.status === 409) {
            handleStatus("already", "Ce patient existe déjà");
        } else if (resp.status === 400) {
            handleStatus("complete", "Veuillez vérifier que tous les champs ont bien été complété");
        } else {
            handleStatus("error", "Une erreur est survenue lors de la modification du patient");
        }
    };

    const handleDelete = async () => {
        const resp = await Patient.deletePatient(patient.id);
        if (resp.status === 200) {
            handleStatus("success", "Le patient a bien été supprimé");
            setOpen("editpatient", true);
        }
    };

    const customStyles = {
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        control: (baseStyles: any, state: { isFocused: any }) => ({
            ...baseStyles,
            boxShadow: state.isFocused ? "0 0 0 1px rgb(0, 51, 107)" : "",
            borderColor: state.isFocused ? "rgb(0, 51, 107)" : "rgb(176, 190, 197)",
            backgroundColor: "transparent",
            "&:hover": {
                borderColor: state.isFocused ? "rgb(0, 51, 107)" : "rgb(176, 190, 197)",
            },
        }),
    };

    const handleSelectChange = (event: any) => {
        if (event !== undefined) {
            console.log(event)
            setPatient({ ...patient, civility: event.value });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 overflow-y-auto">
                <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 border-b pb-2">
                            Modification de {data.firstname.charAt(0).toUpperCase() + data.firstname.slice(1)}{" "}
                            {data.lastname.charAt(0).toUpperCase() + data.lastname.slice(1)}
                        </Dialog.Title>
                        <div className="flex flex-col justify-center">
                            <div className="flex flex-col md:flex-row gap-4 mt-2">
                                <Input
                                    type="text"
                                    color="deep-purple"
                                    value={patient.firstname}
                                    name="firstname"
                                    id="firstname"
                                    onChange={handleInputChange}
                                    label="Prénom"
                                    required
                                />
                                <Input
                                    type="text"
                                    color="deep-purple"
                                    value={patient.lastname}
                                    name="lastname"
                                    id="lastname"
                                    onChange={handleInputChange}
                                    label="Nom"
                                    required
                                />
                            </div>
                            <div className="flex flex-row gap-4 mt-2">
                                <Select
                                    placeholder="Civilité"
                                    name="roles"
                                    className="w-full"
                                    options={civilites}
                                    onChange={(event) => handleSelectChange(event)}
                                    menuPortalTarget={document.body}
                                    styles={customStyles}
                                    value={civilites.filter((civilite) => patient.civility === civilite.value)}
                                    menuPosition="fixed"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                    onClick={() => handleDelete()}
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                    Supprimer
                </button>
                <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                >
                    Modifier
                </button>
                <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen("editpatient")}
                >
                    Fermer
                </button>
            </div>
        </form>
    );
}
