import React, { useEffect, useRef } from "react";
import { Dialog } from "@headlessui/react";
import "tw-elements";

import { Input } from "@material-tailwind/react";
import { toast } from "react-toastify";
import ModalGenerique from "../../components/ModalGenerique";
import PraticerAPI from "../../services/API/clients/PraticerAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ModalPatient({ open, setOpen, user, maj }: Readonly<{ open: boolean; setOpen: (arg?: boolean) => void; user: any; maj: () => void }>) {
    const cancelButtonRef = useRef(null);
    const [loading, setLoading] = React.useState<{ modif: boolean; delete: boolean }>({ modif: false, delete: false });
    const [actualUser, setActualUser] = React.useState({
        lastname: user.lastname,
        firstname: user.firstname,
        profession: user.profession,
        email: user.email,
        phone: user.phone,
        mobilePhone: user.mobilePhone,
    });

    useEffect(() => {
        setActualUser({
            lastname: user.lastname,
            firstname: user.firstname,
            profession: user.profession,
            email: user.email,
            phone: user.phone,
            mobilePhone: user.mobilePhone,
        });
    }, [user]);

    const handleSubmit = async () => {
        setLoading({ ...loading, modif: true });
        const data = {
            firstname: actualUser.firstname,
            lastname: actualUser.lastname,
            profession: actualUser.profession,
            email: actualUser.email,
            phone: actualUser.phone,
            mobilePhone: actualUser.mobilePhone,
        };

        const resp = await PraticerAPI.putUser(user.id, data);
        if (resp.status === 201 || resp.status === 200) {
            toast.success("L'employé a bien été modifié");
            setOpen(true);
        } else if (resp.status === 400) toast.warning("Veuillez vérifier les informations saisies");
        else toast.error("Une erreur est survenue lors de la modification de l'employé");
        setLoading({ ...loading, modif: false });
    };

    const handleInputChange = (event: { target: { name: any; value: any } }) => {
        const { name, value } = event.target;
        setActualUser({ ...actualUser, [name]: value });
    };

    const handleDelete = async () => {
        setLoading({ ...loading, delete: true });
        const resp = await PraticerAPI.deleteUser(user.id);
        if (resp.status === 200) {
            toast.success("L'employé a bien été supprimé");
            maj();
        } else {
            toast.error("Une erreur est survenue lors de la suppression de l'employé");
        }
        setLoading({ ...loading, delete: false });
    };

    return (
        <ModalGenerique open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 border-b pb-2">
                                Edition de {user.firstname} {user.lastname}
                            </Dialog.Title>
                            <div className="mt-4 flex flex-col justify-center">
                                <div className="flex flex-row gap-4">
                                    <Input
                                        type="text"
                                        color="deep-purple"
                                        name="lastname"
                                        id="lastname"
                                        value={actualUser.lastname}
                                        onChange={handleInputChange}
                                        label="Nom"
                                    />
                                    <Input
                                        type="text"
                                        color="deep-purple"
                                        name="firstname"
                                        id="firstname"
                                        value={actualUser.firstname}
                                        onChange={handleInputChange}
                                        label="Prénom"
                                    />
                                </div>
                                <div className="flex flex-row gap-4 mt-2">
                                    <Input
                                        type="text"
                                        color="deep-purple"
                                        name="profession"
                                        id="profession"
                                        value={actualUser.profession}
                                        onChange={handleInputChange}
                                        label="Profession"
                                    />
                                    <Input
                                        type="email"
                                        color="deep-purple"
                                        name="email"
                                        id="email"
                                        value={actualUser.email}
                                        onChange={handleInputChange}
                                        label="Adresse mail"
                                    />
                                </div>
                                <div className="flex flex-row gap-4 mt-2">
                                    <Input
                                        type="tel"
                                        color="deep-purple"
                                        name="phone"
                                        id="phone"
                                        min={10}
                                        max={10}
                                        value={actualUser.phone}
                                        onChange={handleInputChange}
                                        label="Fixe"
                                    />
                                    <Input
                                        type="tel"
                                        color="deep-purple"
                                        name="mobilePhone"
                                        id="mobilePhone"
                                        min={10}
                                        max={10}
                                        value={actualUser.mobilePhone}
                                        onChange={handleInputChange}
                                        label="Mobile"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                        onClick={() => handleSubmit()}
                        className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                    >
                        {loading.modif ? <FontAwesomeIcon icon="spinner" spin /> : "Modifier"}
                    </button>
                    <button
                        type="button"
                        onClick={() => handleDelete()}
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    >
                        {loading.delete ? <FontAwesomeIcon icon="spinner" spin /> : "Supprimer"}
                    </button>
                    <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen()}
                        ref={cancelButtonRef}
                    >
                        Annuler
                    </button>
                </div>
            </Dialog.Panel>
        </ModalGenerique>
    );
}
