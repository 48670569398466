import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface Props {
    handlePageChange: any;
    itemsPerPage: number;
    currentPage: number;
    totalItems: number;
    startIndex: number;
    endIndex: number;
}

const Pagination: React.FC<Props> = ({ handlePageChange, itemsPerPage, currentPage, totalItems, startIndex, endIndex }) => {
    return (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="flex flex-1 items-center justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        <span className="font-medium">{startIndex + 1}</span> à <span className="font-medium">{endIndex + 1}</span> de{" "}
                        <span className="font-medium">{totalItems}</span> résultats
                    </p>
                </div>
                <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        <button
                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            <span className="sr-only">Previous</span>
                            <FontAwesomeIcon icon="chevron-left" className="h-3 w-5" aria-hidden="true" />
                        </button>
                        {Array.from({ length: Math.ceil(totalItems / itemsPerPage) }, (_, index) => (
                            <button
                                key={index + 1}
                                onClick={() => handlePageChange(index + 1)}
                                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                                    currentPage === index + 1
                                        ? "bg-royal-900 text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
                                }`}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button
                            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            disabled={endIndex === totalItems - 1}
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            <span className="sr-only">Next</span>
                            <FontAwesomeIcon icon="chevron-right" className="h-3 w-5" aria-hidden="true" />
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
