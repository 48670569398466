import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Working from "../pages/Working/Working";
import Order from "../pages/Order/Order";
import Waiting from "../pages/Waiting/Waiting";
import Finished from "../pages/Finished/Finished";
import Employee from "../pages/Employee/Employee";
import Patient from "../pages/Patient/Patient";
import Login from "../pages/Auth/login";
import Dash from "../pages/Dash/Dash";
import Facturation from "../pages/Facturation/Facturation";
import InvoiceDocument from "../pages/Facturation/Document";
import Chat from "../pages/Chat/Chat";
import Settings from "../pages/Settings/Settings";
import AskReset from "../pages/Auth/askReset";
import ResetPassword from "../pages/Auth/resetPassword";
import Stripe from "../pages/Stripe/Stripe";
import StripeFail from "../pages/Stripe/StripeFail";
import RegisterStepper from "../pages/Auth/registerStepper";
import Auth from "../pages/Auth/Auth";

interface RouteDisplay {
    icon?: JSX.Element;
    name: string;
}

export interface RouteData {
    path: string;
    auth: string[];
    display?: RouteDisplay;
    element?: JSX.Element;
    routes?: RouteData[];
}

export interface RouteGroup {
    baseUrl?: string;
    title?: string;
    shownInSidebar?: boolean;
    element?: JSX.Element;
    pages: RouteData[];
}

export const routes : RouteGroup[]  = [
    {
        shownInSidebar: false,
        pages: [
            {
                path: "/",
                auth: [],
                element: <Login />
            },
            {
                path: "/auth/:id",
                auth: [],
                element: <Auth />
            },
            {
                path: "/stripe",
                auth: [],
                element: <Stripe />
            },
            {
                path: "/stripe-fail",
                auth: [],
                element: <StripeFail />
            },
            {
                path: "/register",
                auth: [],
                element: <RegisterStepper />
            },
            {
                path: "/ask-reset",
                auth: [],
                element: <AskReset />
            },
            {
                path: "/reset-password/:id",
                auth: [],
                element: <ResetPassword />
            },
            {
                path: "/dash",
                auth: ["ADMIN", "COMPANY", "PRACTICER"],
                element: <Dash />
            },
        ],
    },
    {
        baseUrl: "dash",
        element: <Dash />,
        shownInSidebar: false,
        pages: [
            {
                path: "chat",
                auth: ["ADMIN", "COMPANY", "PRACTICER"],
                element: <Chat />
            },
            {
                path: "chat/:id",
                auth: ["ADMIN", "COMPANY", "PRACTICER"],
                element: <Chat />
            },
            {
                path: "settings",
                auth: ["ADMIN", "COMPANY", "PRACTICER"],
                element: <Settings />
            }
        ]   
    },
    {
        baseUrl: "dash",
        element: <Dash />,
        shownInSidebar: true,
        pages: [
            {
                display: {
                    icon: <FontAwesomeIcon icon="tooth" className="w-5 h-5 text-inherit" />,
                    name: "Commande",
                },
                path: "order",
                auth: ["ADMIN", "COMPANY", "PRACTICER"],
                element: <Order />,
            },
            {
                display: {
                    icon: <FontAwesomeIcon
                        icon="arrows-rotate"
                        className="w-5 h-5 text-inherit"
                    />,
                    name: "En attente",
                },
                path: "waiting",
                auth: ["ADMIN", "COMPANY", "PRACTICER"],
                element: <Waiting />,
            },
            {
                display: {
                    icon: <FontAwesomeIcon
                        icon="folder-open"
                        className="w-5 h-5 text-inherit"
                    />,
                    name: "En cours"
                },
                path: "working",
                auth: ["ADMIN", "COMPANY", "PRACTICER"],
                element: <Working />,
            },
            {
                display: {
                    icon: <FontAwesomeIcon
                        icon="folder-closed"
                        className="w-5 h-5 text-inherit"
                    />,
                    name: "Terminées"
                },
                path: "finished",
                auth: ["ADMIN", "COMPANY", "PRACTICER"],
                element: <Finished />,
            },
        ],
    },
     {
        title: "Gestion",
        baseUrl: "dash",
        element: <Dash />,
        shownInSidebar: true,
        pages: [
            {
                display: {
                    icon: <FontAwesomeIcon
                        icon="building"
                        className="w-5 h-5 text-inherit"
                    />,
                    name: "Entreprise"
                },
                path: "enterprise",
                auth: ["ADMIN", "COMPANY"],
                element: <Employee />,
            },
            {
                display: {
                    icon: <FontAwesomeIcon
                        icon="people-group"
                        className="w-5 h-5 text-inherit"
                    />,
                    name: "Patient"
                },
                path: "patient",
                auth: ["ADMIN", "COMPANY", "PRACTICER"],
                element: <Patient />,
            },
            {
                display: {
                    icon: <FontAwesomeIcon icon="file-invoice" className="w-5 h-5 text-inherit" />,
                    name: "Facturation",
                },
                path: "facturation",
                auth: ["ADMIN", "COMPANY", "PRACTICER"],
                routes: [
                    {
                        path: "",
                        auth: ["ADMIN", "COMPANY", "PRACTICER"],
                        element: <Facturation />,
                    },
                    {
                        path: "document/:id",
                        auth: ["ADMIN", "COMPANY", "PRACTICER"],
                        element: <InvoiceDocument />,
                    }
                ]
            },
        ],
    },
];

export function getAllowedRoutes(roles: string[]): RouteGroup[] {
    return routes.map((routeGroup) => ({
        ...routeGroup,
        pages: routeGroup.pages.filter((route) =>
            route.auth.some((auth) => roles.includes(auth))
        ),
    }));
}