import APIClient from "../APIClients";

export default class JawTrackerAPI {
    public static async uploadFiles(data: {}, patientId: string, id: string) {
        return APIClient.client.postPicture(`/tracking/upload/${patientId}?orderId=${id}`, data);
    }

    public static async getStatus(id: string) {
        return APIClient.client.get(`/tracking/${id}/status`);
    }

    public static async getFileName(id: string, name: string) {
        return APIClient.client.getBlob(`/tracking/${id}/file/${name}`)
    }

    public static async getFileNameNoBlopb(id: string, name: string) {
        return APIClient.client.get(`/tracking/${id}/file/${name}`)
    }

    public static async process(id: string, data: {}) {
        return APIClient.client.post(`/tracking/${id}/process`, data);
    }

    public static async getFind(id: string) {
        return APIClient.client.get(`/tracking/find?orderId=${id}`)
    }    
}
