import { Input } from "@material-tailwind/react";
import { useState } from "react";

import PasswordStrengthBar from "react-password-strength-bar";
import { toast } from "react-toastify";
import Auth from "../../services/API/clients/AuthAPI";
import { Storage } from "../../services/storage";

export default function Settings() {
    const [passwords, setPasswords] = useState({
        password: "",
        passwordConfirm: "",
        oldPassword: "",
    });
    const accountId = Storage.getAccountId();

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswords({
            ...passwords,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (passwords.password !== passwords.passwordConfirm) {
            toast.warning("Les mots de passe ne correspondent pas");
            return;
        }

        if (passwords.oldPassword === "") {
            toast.warning("Veuillez entrer votre ancien mot de passe");
            return;
        }

        if (passwords.password.length < 8) {
            toast.warning("Le mot de passe doit faire au moins 8 caractères");
            return;
        } else if (passwords.password.length > 32) {
            toast.warning("Le mot de passe ne doit pas dépasser 32 caractères");
            return;
        }
        else if (!/\d/.test(passwords.password)) {
            toast.warning("Le mot de passe doit contenir au moins un chiffre");
            return;
        }
        else if (!/[a-zA-Z]/.test(passwords.password)) {
            toast.warning("Le mot de passe doit contenir au moins une lettre");
            return;
        }
        else if (!/[^a-zA-Z0-9]/.test(passwords.password)) {
            toast.warning(
                "Le mot de passe doit contenir au moins un caractère spécial"
            );
            return;
        }

        const data = {
            password: {
                old: passwords.oldPassword,
                new: passwords.password,
            }
        }

        const response = await Auth.updatePassword(data, accountId ?? "")

        if (response.status === 200 || response.status === 201) {
            toast.success("Mot de passe modifié");
        } else if (response.body.status === 403) {
            toast.error("Ancien Mot de passe incorrect");
        } else {
            toast.error("Erreur lors de la modification du mot de passe");
        }
    }

    return (
        <div className="mt-6 mb-8 flex flex-row gap-4 bg-white rounded-xl p-4 grow h-[75vh]">
            <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full">
                <Input
                    type="password"
                    color="deep-purple"
                    size="lg"
                    label="Nouveau mot de passe"
                    name="password"
                    onChange={handlePasswordChange}
                />
                <Input
                    type="password"
                    color="deep-purple"
                    size="lg"
                    label="Confirmer le mot de passe"
                    name="passwordConfirm"
                    onChange={handlePasswordChange}
                />
                <Input
                    type="password"
                    color="deep-purple"
                    size="lg"
                    label="Ancien mot de passe"
                    name="oldPassword"
                    onChange={handlePasswordChange}
                />
                <div className="w-full">
                    <PasswordStrengthBar password={passwords.password} />
                </div>
                <button type="submit" className="bg-royal-900 hover:bg-royal-900/80 text-white mx-auto text-base font-bold py-4 px-16 rounded w-fit">
                    Modifier le mot de passe    
                </button>
            </form>
        </div>
    )
}