import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "@material-tailwind/react";
import React from "react";
import Auth from "../../services/API/clients/AuthAPI";
import { toast } from "react-toastify";

export default function AskReset() {
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState("");

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        const datas = {
            value: email,
        };

        const response = await Auth.askReset(datas);
        if (response.status === 200 || response.status === 201) {
            alert("Un email vous a été envoyé");
        } else {
            toast.error("Une erreur est survenue");
        }

        setLoading(false);
    };

    const handleInputChange = (event: { target: { name: any; value: any } }) => {
        setEmail(event.target.value);
    };

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center overflow-hidden">
            <div className="relative py-3 mx-auto w-full md:w-1/2">
                <div className="absolute inset-0 bg-gradient-to-r from-capucine-700 to-capucine-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                <div className="relative px-2 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-14 mx-auto">
                    <h1 className="text-2xl text-royal-900 font-semibold">Mot de passe oublié</h1>
                    <form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
                        <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                            <div className="relative">
                                <Input
                                    autoComplete="off"
                                    id="email"
                                    name="email"
                                    type="email"
                                    variant="standard"
                                    color="deep-purple"
                                    label="Adresse mail"
                                    value={email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="relative">
                                <button className="bg-royal-900 hover:bg-royal-800 text-white rounded-md px-2 py-1">
                                    {loading && <FontAwesomeIcon icon="spinner" className="animate-spin" />} Changer le mot de passe
                                </button>

                                <a href="/" className="block text-left mt-2 text-sm">
                                    Vous avez retrouvé votre mot de passe ? <b>Connectez-vous</b>
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
