interface BottomTutorialProps {
    step: number;
    endTutorial: Function;
    handleClickStart: (e: React.MouseEvent<HTMLElement>) => void;
    playTutorial: boolean;
}

export default function BottomTutorial({ step, endTutorial, handleClickStart, playTutorial }: Readonly<BottomTutorialProps>) {
    if (playTutorial)
        return (
            <div className="absolute bottom-0 left-0 z-[99999] h-1/4 flex flex-col gap-3 justify-center items-center bg-gradient-to-t from-black/80 to-transparent w-full text-white">
                <span className="text-xl pt-4">Cliquez sur les bulles rouges pour découvrir les fonctionnalités de l'application.</span>
                <div className="flex gap-2">
                    <button onClick={() => endTutorial()} className="bg-capucine-700 text-white px-4 py-1 rounded hover:bg-capucine-600 transition-all">
                        Passer le tutoriel
                    </button>
                    <button onClick={handleClickStart} className="bg-royal-900 text-white px-4 py-1 rounded hover:bg-royal-800 transition-all">
                        {step > 0 ? "Reprendre le tutoriel" : "Lancer le tutoriel"}
                    </button>
                </div>
            </div>
        );
    return <></>;
}
