import { Dialog } from "@headlessui/react";
import { Alert, Card, IconButton, Input, Textarea, Typography } from "@material-tailwind/react";
import moment from "moment";
import React, { useCallback, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Verification from "../../../services/API/clients/OrderAPI";

export default function EndModalTutorial({
    setOpen,
    data,
    handleStatus,
}: Readonly<{ setOpen: (open: string, arg0?: boolean) => void; data: any; handleStatus: any }>) {
    const cancelButtonRef = useRef(null);
    const [files, setFiles] = React.useState<any>({});

    const fetchFiles = useCallback(async () => {
        const response = await Verification.getFiles(data.id);
        if (response.status === 200) setFiles(response.body);
    }, [data]);

    useEffect(() => {
        fetchFiles();
    }, [fetchFiles]);

    const getMyFile = async (id: string, name: string) => {
        await Verification.downloadFile(id, name);
    };

    const downloadAllOfThem = (key: string) => {
        files[key].files.forEach((fichier: { id: string; name: string }) => {
            getMyFile(fichier.id, fichier.name);
        });
    }

    return (
        <>
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-left sm:ml-4 sm:mt-0">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 border-b pb-2">
                            Modification de {data?.code}
                        </Dialog.Title>
                        <div className="flex flex-row gap-4 justify-center">
                            <div className="flex flex-row flex-wrap mt-2 gap-2 w-1/3 items-start justify-start content-start">
                                <Alert className="my-2" color="deep-purple"><b>Echéance : </b> {moment(data.deliveryTarget).format("DD.MM.YYYY")}</Alert>
                                <Input label="Patient" type="text" color="blue-gray" value={data.practicerFullName} className="w-96" disabled />
                                <Input label="Client" type="text" color="blue-gray" value={data.patientFullName} className="w-96" disabled />
                                <Input label="Laboratoire" type="text" color="blue-gray" value={data.companyName} className="w-96" disabled />
                                <Input
                                    label="Date de création"
                                    type="text"
                                    color="blue-gray"
                                    value={moment(data.createdAt).format("DD/MM/YYYY")}
                                    className="w-96"
                                    disabled
                                />
                                <Textarea label="Commentaire" color="blue-gray" value={data.comment} className="w-96" disabled />
                                <div className="w-full h-auto rounded-md px-4 pt-2" style={{ backgroundColor: "#eceff1" }}>
                                    {files !== undefined && Object.keys(files).length > 0 ? (
                                        <ul className="mb-1">
                                            {Object.keys(files).map((key) => (
                                                <li key={files[key].id} className="mb-1">
                                                    <Typography variant="small" className="font-normal text-blue-gray-700 text-base">
                                                        • {files[key].name} {files[key].files.length > 0 && <IconButton size="sm" className="text-xs ml-1" color="lime" onClick={() => downloadAllOfThem(key)}><FontAwesomeIcon icon="download"  className="text-white" /></IconButton>}
                                                    </Typography>
                                                    <ul className="mt-0.5 ml-6">
                                                        {files[key].files.length === 0 ? (
                                                            <li>
                                                                <Typography variant="small" className="font-normal text-blue-gray-700 text-sm">
                                                                    Aucun fichier
                                                                </Typography>
                                                            </li>
                                                        ) : (
                                                            files[key].files.map((fichier: { id: string; name: string }) => (
                                                                <li key={fichier.id}>
                                                                    <Typography
                                                                        variant="small"
                                                                        className="font-normal text-blue-gray-700 text-sm flex items-center"
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon="download"
                                                                            onClick={() => getMyFile(fichier.id, fichier.name)}
                                                                            className="bg-royal-900 hover:bg-royal-800 p-2 text-xs text-white rounded-lg mr-2 mb-0.5 cursor-pointer"
                                                                        />
                                                                        {fichier.name}
                                                                    </Typography>
                                                                </li>
                                                            ))
                                                        )}
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <Input color="blue-gray" className="font-normal text-left pt-2 pl-2" value="Aucun fichiers" disabled />
                                    )}
                                </div>
                            </div>
                            <div className="overflow-x-auto pt-2 w-2/3">
                                <Card className="overflow-scroll h-auto w-full">
                                    <table className="w-full table-auto text-left">
                                        <thead>
                                            <tr>
                                                {["Identifiant", "Dents", "Code produit", "Prix", "Description"].map((head) => (
                                                    <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                        <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                                                            {head}
                                                        </Typography>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.products.map((product: any, index: number) => {
                                                const isLast = index === data.products.length - 1;
                                                const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                                                return (
                                                    <tr key={product.productRef.code}>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                {data?.code}-{index}
                                                            </Typography>
                                                        </td>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal min-w-[100px]">
                                                                {product.teeth.join(", ")}
                                                            </Typography>
                                                        </td>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal whitespace-nowrap">
                                                                {product.productRef.code}
                                                            </Typography>
                                                        </td>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                {product.productRef.price}
                                                            </Typography>
                                                        </td>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal whitespace-nowrap">
                                                                {product.productRef.description}
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen("allInOne")}
                    ref={cancelButtonRef}
                >
                    Fermer
                </button>
            </div>
        </>
    );
}
