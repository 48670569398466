import { Dialog } from "@headlessui/react";

import ModalGeneriqueTutorial from "./ModalGenerique";

import EmployeeModalTutorial from "./Employee";
import PatientModalTutorial from "./Patient";
import InvalidModalTutorial from "./Invalid";
import VerifClientTutorial from "./VerifClient";
import EndModalTutorial from "./EndModal";

import { toast } from "react-toastify";

export default function ModalGlobalTutorial({
    open,
    setOpen,
    type,
    data,
    maj,
}: Readonly<{
    open: boolean;
    setOpen: (open: string, arg0?: boolean) => void;
    type: string;
    data: any;
    maj: () => void;
}>) {
    const handleStatus = (state: string, msg: string) => {
        if (state === "error") toast.error(msg);
        else if (state === "success") {
            toast.success(msg);
            maj();
        } else if (state === "already") toast.error(msg);
        else if (state === "complete") toast.warning(msg);
        else console.log("error")
    };

    return (
        <ModalGeneriqueTutorial open={open} setOpen={setOpen} type={type}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-auto sm:mx-8">
                {type === "employee" && <EmployeeModalTutorial setOpen={setOpen} data={data} handleStatus={handleStatus} />}
                {type === "patient" && <PatientModalTutorial setOpen={setOpen} data={data} handleStatus={handleStatus} />}
                {type === "invalid" && <InvalidModalTutorial setOpen={setOpen} data={data} />}
                {type === "working" && <VerifClientTutorial setOpen={setOpen} data={data} handleStatus={handleStatus} />}
                {type === "finished" && <EndModalTutorial setOpen={setOpen} data={data} handleStatus={handleStatus} />}
            </Dialog.Panel>
        </ModalGeneriqueTutorial>
    );
}
