import React, { useRef } from "react";
import { Dialog } from "@headlessui/react";
import "tw-elements";

import { Input } from "@material-tailwind/react";
import { toast } from "react-toastify";
import PraticerAPI from "../../services/API/clients/PraticerAPI";
import ModalGenerique from "../../components/ModalGenerique";
import HandleErrors from "../../components/HandleErrors";

import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Storage } from "../../services/storage";

const civilites = [
    { label: "Monsieur", value: "MAN" },
    { label: "Madame", value: "WOMAN" },
    { label: "Autre", value: "OTHER" },
];

export default function ModalPatient({ open, setOpen, maj }: Readonly<{ open: boolean; setOpen: () => void; maj: () => void }>) {
    const cancelButtonRef = useRef(null);
    const [status, setStatus] = React.useState({
        message: "",
    });
    const [loading, setLoading] = React.useState(false);
    const [employee, setEmployee] = React.useState({
        lastname: "",
        firstname: "",
        profession: "",
        email: "",
        password: "",
        confirmPass: "",
        phone: "",
        mobilePhone: "",
        civility: "OTHER",
    });

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            firstname: employee.firstname,
            lastname: employee.lastname,
            profession: employee.profession,
            email: employee.email,
            password: employee.password,
            mobilePhone: "+33" + employee.mobilePhone.replace(/^0/, ""),
            ...(employee.phone !== "" && { phone: "+33" + employee.phone.replace(/^0/, "") }),
            civility: employee.civility,
            companyId: Storage.getCompanyId() ?? "",
        };

        const resp = await PraticerAPI.newUser(data);
        const { body } = resp;
        if (resp.status === 201) {
            toast.success("L'employé a bien été créé");
            maj();
            clearForm();
        } else if (resp.body.statusCode === 400) {
            toast.warning("Veuillez vérifier que tous les champs ont bien été complété");
            setStatus({ message: body.message });
        } else if (resp.body.statusCode === 409) {
            toast.warning("Cet employé existe déjà");
            setStatus({ message: body.exception.message });
        } else {
            toast.error("Une erreur est survenue lors de la création de l'employé");
        }
        setLoading(false);
    };

    const clearForm = () => {
        setEmployee({
            lastname: "",
            firstname: "",
            profession: "",
            email: "",
            password: "",
            confirmPass: "",
            phone: "",
            mobilePhone: "",
            civility: "OTHER",
        });
    };

    const handleInputChange = (event: { target: { name: any; value: any } }) => {
        const { name, value } = event.target;
        setEmployee({ ...employee, [name]: value });
    };

    const customStyles = {
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        control: (baseStyles: any, state: { isFocused: any }) => ({
            ...baseStyles,
            boxShadow: state.isFocused ? "0 0 0 1px rgb(0, 51, 107)" : "",
            borderColor: state.isFocused ? "rgb(0, 51, 107)" : "rgb(176, 190, 197)",
            backgroundColor: "transparent",
            "&:hover": {
                borderColor: state.isFocused ? "rgb(0, 51, 107)" : "rgb(176, 190, 197)",
            },
        }),
    };

    const handleSelectChange = (event: any) => {
        if (event !== undefined) {
            setEmployee({ ...employee, civility: event.value });
        }
    };

    return (
        <ModalGenerique open={open} setOpen={setOpen}>
            <form onSubmit={handleSubmit}>
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 border-b pb-2">
                                    Nouvel Utilisateur
                                </Dialog.Title>
                                <div className="mt-4 flex flex-col justify-center">
                                    <div className="flex flex-row gap-4">
                                        <Input
                                            type="text"
                                            color="deep-purple"
                                            name="lastname"
                                            id="lastname"
                                            onChange={handleInputChange}
                                            label="Nom"
                                            error={status.message.includes("lastname should not be empty") || status.message.includes("name must be a string")}
                                            required
                                        />
                                        <Input
                                            type="text"
                                            color="deep-purple"
                                            name="firstname"
                                            id="firstname"
                                            onChange={handleInputChange}
                                            label="Prénom"
                                            error={
                                                status.message.includes("firstname should not be empty") ||
                                                status.message.includes("firstname must be a string")
                                            }
                                            required
                                        />
                                        {status.message.includes("lastname should not be empty") && (
                                            <HandleErrors error="Le nom ne pas être vide" color="red" />
                                        )}
                                        {status.message.includes("lastname should not be empty") && (
                                            <HandleErrors error="Le nom doit être une chaîne de caractères" color="red" />
                                        )}
                                        {status.message.includes("firstname must be a string") && (
                                            <HandleErrors error="Le prénom doit être une chaîne de caractères" color="red" />
                                        )}
                                        {status.message.includes("firstname should not be empty") && (
                                            <HandleErrors error="Le prénom ne peut pas être vide" color="red" />
                                        )}
                                    </div>
                                    <div className="flex flex-row gap-4 mt-2">
                                        <Input
                                            type="text"
                                            color="deep-purple"
                                            name="profession"
                                            id="profession"
                                            onChange={handleInputChange}
                                            label="Profession"
                                            error={status.message.includes("profession must be a string")}
                                            required
                                        />
                                        <Input
                                            type="email"
                                            color="deep-purple"
                                            name="email"
                                            id="email"
                                            onChange={handleInputChange}
                                            label="Adresse mail"
                                            error={status.message.includes("email must be an email")}
                                            required
                                        />
                                    </div>
                                    <div className="flex flex-row gap-4 mb-2 w-full">
                                        <div className="w-1/2">
                                            {status.message.includes("profession should not be empty") && (
                                                <HandleErrors error="La profession du contact principal ne peut pas être vide" color="red" />
                                            )}
                                        </div>
                                        <div className="w-1/2">
                                            {status.message.includes("email must be an email") && (
                                                <HandleErrors error="L'adresse mail doit être une adresse mail valide" color="red" />
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-row gap-4 mt-2">
                                        <div className="w-full">
                                            <Input
                                                type="tel"
                                                color="deep-purple"
                                                name="mobilePhone"
                                                id="mobilePhone"
                                                onChange={handleInputChange}
                                                label="Téléphone mobile"
                                                error={
                                                    status.message.includes("mobilePhone must be a valid phone number") ||
                                                    status.message.includes("mobilePhone must be a phone number")
                                                }
                                                required
                                            />
                                        </div>
                                        <Input
                                            type="tel"
                                            color="deep-purple"
                                            name="phone"
                                            id="phone"
                                            onChange={handleInputChange}
                                            label="Téléphone fixe"
                                            error={status.message.includes("phone must be a valid phone number")}
                                        />
                                    </div>
                                    <div className="flex flex-row gap-4 mb-2 w-full">
                                        <div className="w-1/2">
                                            {status.message.includes("phone must be a valid French phone number.") && (
                                                <HandleErrors error="Le numéro de téléphone doit être un numéro de téléphone français valide" color="red" />
                                            )}
                                        </div>
                                        <div className="w-1/2">
                                            {status.message.includes("mobilePhone must be a valid French phone number.") && (
                                                <HandleErrors
                                                    error="Le numéro de téléphone portable doit être un numéro de téléphone portable français valide"
                                                    color="red"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-row flex-wrap gap-4 mt-2">
                                        <div style={{ width: "48%" }}>
                                            <Select
                                                placeholder="Civilité"
                                                name="roles"
                                                className="w-full"
                                                options={civilites}
                                                onChange={(event) => handleSelectChange(event)}
                                                menuPortalTarget={document.body}
                                                styles={customStyles}
                                                value={civilites.filter((civilite) => employee.civility === civilite.value)}
                                                menuPosition="fixed"
                                                required
                                            />
                                        </div>
                                        <div style={{ width: "48%" }}>
                                            <Input
                                                type="password"
                                                color="deep-purple"
                                                name="password"
                                                id="password"
                                                onChange={handleInputChange}
                                                label="Mot de Passe"
                                                error={status.message.includes("password is not strong enough")}
                                                required
                                            />
                                        </div>
                                        {!status.message.includes("password is not strong enough") && (
                                            <HandleErrors
                                                error="Au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial"
                                                color="gray"
                                            />
                                        )}
                                        {status.message.includes("password is not strong enough") && (
                                            <HandleErrors
                                                error="Au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial"
                                                color="gray"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                            type="submit"
                            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto validateFormNew-step"
                        >
                            {loading ? <FontAwesomeIcon icon="spinner" spin /> : "Créer"}
                        </button>
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={() => setOpen()}
                            ref={cancelButtonRef}
                        >
                            Annuler
                        </button>
                    </div>
                </Dialog.Panel>
            </form>
        </ModalGenerique>
    );
}
