import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Input, Spinner, Typography } from "@material-tailwind/react";
import { useEffect, useRef, useState } from "react";
import OrderAPI from "../../services/API/clients/OrderAPI";
import MessagerieAPI from "../../services/API/clients/MessagerieAPI";
import moment from "moment";
import Auth from "../../services/API/clients/AuthAPI";
import { useParams } from "react-router-dom";
import NotificationAPI from "../../services/API/clients/NotificationAPI";
import { useDropzone } from 'react-dropzone';
import { Storage } from "../../services/storage";

export default function Chat() {
    const [notifications, setNotifications] = useState<any[]>([]);
    const [onSelect, setOnSelect] = useState("");
    const [inputMessage, setInputMessage] = useState("");
    const [messages, setMessages] = useState<any[]>([]);
    const [myData, setMyData] = useState([]);
    const [error, setError] = useState(false);
    const [fileList, setFileList] = useState<any[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const [loadingMessages, setLoadingMessages] = useState(true);
    const { id } = useParams();

    const myId = Storage.getContactId() ?? ""

    const onDrop = (acceptedFiles: any) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const newFiles = Array.from(acceptedFiles).map((file: any) => ({
                file,
                preview: URL.createObjectURL(file),
                name: file.name,
            }));
            setFileList((prevFiles) => [...prevFiles, ...newFiles]);
        }
    };

    const { getRootProps, isDragActive } = useDropzone({ onDrop,
        noClick: true,
        noKeyboard: true 
    });

    const addFile = (files: FileList | null) => {
        if (files && files.length > 0) {
            const newFiles = Array.from(files).map((file) => ({
                file,
                preview: URL.createObjectURL(file),
                name: file.name,
            }));
            setFileList((prevFiles) => [...prevFiles, ...newFiles]);
        }
    };

    const removeFile = (index: number) => {
        const newFiles = [...fileList];
        const removedFile = newFiles.splice(index, 1)[0];
        URL.revokeObjectURL(removedFile.preview);
        setFileList(newFiles);
    };

    const handleFileInputChange = () => {
        const files = fileInputRef.current?.files;
        if (files) addFile(files);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    async function fetchNotifications() {
        const response = await NotificationAPI.getNotifs();
        if (response.status === 200 || response.status === 201 || response.status === 304) {
            setNotifications(response.body);
        } else setError(true);
    }


    const deleteNotif = async (id: string) => {
        if (!id) return;
        const allIds = notifications.map((notif: any) => {
            if (notif.action === "messageSent" && notif.context.orderId === id) return notif.id;
            return undefined;
        });
        const uniqueIds = allIds.filter((value: string, index: number, self: any) => self.indexOf(value) === index);
        const removeUndefined = uniqueIds.filter((value: any) => value !== undefined);
        if (removeUndefined.length > 0) {
            const notifDone = await NotificationAPI.doneNotif({ ids: removeUndefined });
            if (notifDone.status === 200) {
                fetchNotifications();
            } else setError(true);
        }
    };

    const sendMessage = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (loading) return;
        setLoading(true);

        if (inputMessage === "" && fileList.length === 0) {
            setLoading(false);
            return;
        }


        if (fileList.length > 0) {
            const formData = new FormData();
            fileList.forEach((file) => {
                formData.append("files", file.file);
            });
            formData.append("messagingId", messages[0].id);
            if (inputMessage !== "")
                formData.append("message", inputMessage);

            const response = await MessagerieAPI.newMessageWithPicture(formData);
            if (response.status === 200 || response.status === 201 || response.status === 304) {
                getMessages();
                setFileList([]);
                setInputMessage("");
                setLoading(false);
            } else {
                setError(true);
                setLoading(false);
            }
        } else {
            const datas = {
                messagingId: messages[0].id,
                message: inputMessage,
            };

            const response = await MessagerieAPI.newMessage(datas);
            if (response.status === 200 || response.status === 201 || response.status === 304) {
                getMessages();
                setInputMessage("");
                setLoading(false);
            } else {
                setError(true);
                setLoading(false);
            }
        }
    };

    const getMessages = async () => {
        const response = await MessagerieAPI.message(onSelect);
        if (response.status === 200 || response.status === 201 || response.status === 304) {
            setMessages(response.body);
        } else setError(true);
        setLoadingMessages(false);
    };

    const handleMAJUnassigned = async () => {
        const response = await MessagerieAPI.messages();
        if (response.status === 200 || response.status === 304) {
            setMyData(response.body);
            if (id) {
                setOnSelect(id);
                const response = await MessagerieAPI.message(id);
                if (response.status === 200 || response.status === 201 || response.status === 304) {
                    setMessages(response.body);
                } else setError(true);
            } else if (response.body.length > 0) {
                setOnSelect(response.body[0].orderId);
                const resp = await MessagerieAPI.message(response.body[0].orderId);
                if (resp.status === 200 || resp.status === 201 || resp.status === 304) {
                    setMessages(resp.body);
                } else setError(true);
            } else console.error("no command");
            setLoadingMessages(false);
        } else setError(true);
    }

    useEffect(() => {
        handleMAJUnassigned();
        fetchNotifications();
    }, []);

    useEffect(() => {
        getMessages();
        deleteNotif(onSelect);
    }, [onSelect]);

    const getMyFile = async (id: string, name: string) => {
        const dataFile = await OrderAPI.infoFiles(id)
        if (dataFile.status === 200 || dataFile.status === 201 || dataFile.status === 304) {
            await OrderAPI.downloadFile(id, dataFile.body.name);
        }
    };

    return (
        <div className="mt-6 mb-8 flex flex-row gap-4 bg-white rounded-xl p-4 grow h-[75vh]">
            {!error 
                ? myData.length > 0 
                    ? (
                        <>
                            <div className="w-1/4 grow h-full overflow-y-auto border-r-2 flex flex-col">
                                {myData.map((item: any) => (
                                    <button
                                        key={item.code}
                                        onClick={() => setOnSelect(item.orderId)}
                                        className={`flex flex-row py-4 px-2 items-center border-b-2 border-l-4 ${
                                            onSelect === item.orderId ? "border-l-capucine-700" : "border-l-transparent"
                                        }`}
                                    >
                                        <div className="w-full flex flex-col justify-center items-center">
                                            <div className="text-lg font-semibold">
                                                {notifications.some((notif: any) => notif.type === "MESSAGING" && notif.context.orderId === item.orderId) ? (
                                                    <div className="w-fit flex gap-1 justify-center items-center">
                                                        <div className="w-2 h-2 rounded-full bg-capucine-700"></div>
                                                        <div className="text-lg font-semibold">{item.orderCode}</div>
                                                    </div>
                                                ) : (
                                                    <div className="text-lg font-semibold">{item.orderCode}</div>
                                                )}
                                            </div>
                                            <span className="text-gray-500">{item.companyFullName}</span>
                                        </div>
                                    </button>
                                ))}
                            </div>
                            <div className="w-3/4 grow h-full overflow-y-auto relative flex flex-col justify-between px-5" {...getRootProps()}>
                                {isDragActive && (
                                    <div className="flex flex-col items-center justify-center absolute top-0 left-0 w-full h-full bg-gray-300/50 z-50">
                                        <FontAwesomeIcon icon="cloud" />
                                        <Typography variant="h5" className="text-gray-900">
                                            Déposez vos fichiers ici
                                        </Typography>
                                    </div>
                                )}
                                <div className="flex flex-col overflow-y-auto">
                                {!loadingMessages 
                                    ? messages.length > 0 && messages[0] !== undefined && messages[0].messages.map((item: any) => (
                                            <div className={`flex ${item.writerId === myId ? "justify-end" : "justify-start"} mb-4`} key={item.id}>
                                                <div className="flex flex-col">
                                                    <div
                                                        className={`${
                                                            item.writerId === myId
                                                                ? "mr-2 bg-gray-200 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl text-royal-900"
                                                                : "ml-2 bg-royal-900 rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white"
                                                        } py-3 px-4`}
                                                    >
                                                        <div className={`text-xs text-gray-500 ${item.writerId === myId ? "text-right" : "text-left"}`}>
                                                            {moment(item.datetime).format("DD.MM.YYYY")}
                                                        </div>
                                                        {item.message}
                                                    </div>
                                                    {item.linkedFoldersIds.length > 0 && (
                                                        <div className={`flex mt-1 gap-2 ${item.writerId === myId
                                                            ? "justify-end"
                                                            : "ml-2 justify-start"}`}>
                                                            {item.linkedFoldersIds.map((file: any, index: number) => (
                                                                <FontAwesomeIcon
                                                                    key={file}
                                                                    icon="file"
                                                                    onClick={() => getMyFile(file, moment(item.datetime).format("YYYY-MM-DD"))}
                                                                    className={`${item.writerId !== myId ? "bg-royal-900 hover:bg-royal-800" : "bg-gray-200 hover:bg-gray-100"} p-2 text-xs text-white rounded-lg mr-2 mb-0.5 cursor-pointer`}
                                                                />
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )) : (
                                            <Spinner color="deep-purple" className="mx-auto w-8 h-8 mt-4" />
                                        )
                                    }
                                </div>
                                <div className="py-2 flex flex-col items-center gap-2">
                                    <div className="flex justify-start w-full gap-4 overflow-x-auto h-fit pt-4 pb-1">
                                        {fileList.map((file, index) => (
                                            <div key={file} className="relative flex flex-col items-center gap-2 w-24 py-2 px-2 bg-royal-900/10 rounded">
                                                {file.file.type.startsWith("image/") ? (
                                                    <img src={file.preview} alt={`file-${index}`} className="w-24 h-16 object-cover rounded" />
                                                ) : (
                                                    <FontAwesomeIcon icon="file" className="text-royal-900 w-24 h-16" />
                                                )}
                                                <button
                                                    color="lime"
                                                    onClick={() => removeFile(index)}
                                                    className="absolute -top-3 -right-3  bg-capucine-700/80 h-8 w-8 rounded-xl flex justify-center items-center"
                                                >
                                                    <FontAwesomeIcon icon="trash-alt" className="text-white text-sm" />
                                                </button>
                                                <Typography variant="small" className="truncate whitespace-nowrap w-24 text-center">
                                                    {file.file.name}
                                                </Typography>
                                            </div>
                                        ))}
                                    </div>
                                    <form onSubmit={sendMessage} className="flex items-center gap-2 w-full">
                                        <Input
                                            color="gray"
                                            label="Message"
                                            value={inputMessage}
                                            onChange={(e) => setInputMessage(e.target.value)}
                                            onPaste={(e) => addFile(e.clipboardData.files)}
                                            className="w-full bg-white"
                                            type="text"
                                        />
                                        <div className="flex gap-2">
                                            <input ref={fileInputRef} type="file" multiple onChange={handleFileInputChange} className="hidden" />
                                            <IconButton type="button" onClick={() => fileInputRef.current?.click()} color="blue" variant="text">
                                                <FontAwesomeIcon icon="file" className="text-royal-900" />
                                            </IconButton>
                                        </div>
                                        <IconButton type="submit" color="blue" variant="text">
                                            {loading 
                                                ? <Spinner color="gray" />
                                                : <FontAwesomeIcon icon="paper-plane" className="text-royal-900" />
                                            }
                                        </IconButton>
                                    </form>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="flex flex-col justify-center items-center w-full h-full">
                            <div className="text-4xl font-semibold text-gray-500">Aucune commande n'a été passée</div>
                        </div>
                    )
                : (
                    <div className="flex flex-col justify-center items-center w-full h-full">
                        <div className="text-4xl font-semibold text-gray-500">Chargement...</div>
                    </div>
                )
            }
        </div>
    );
}
