import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-tailwind/react";
import { useDropzone } from "react-dropzone";

export function FileDropZone({ handleFilesChange, fileList, removeLineFile, name, filesTypes, setFileList }: Readonly<{ handleFilesChange: (event: any) => Promise<void>, fileList: File[], removeLineFile: (index: number) => void, name: string, filesTypes: string, setFileList: any }>) { 
    const onDrop = (acceptedFiles: any) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            setFileList((prevFiles: File[]) => [...prevFiles, ...acceptedFiles]);
        }
    };

    const { getRootProps, isDragActive } = useDropzone({ onDrop,
        noClick: true,
        noKeyboard: true 
    });
    
    return (
        <div className="flex flex-col md:flex-row gap-3 relative">
            <div className="flex items-center justify-center w-full md:w-1/2 my-2 grow">
                <label htmlFor={name + "dropzone-file"} className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 relative"   {...getRootProps()}>
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">Fichiers <span className="font-semibold">{name}</span></p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">{filesTypes}</p>
                    </div>
                    <input id={name + "dropzone-file"} type="file" className="hidden" onChange={handleFilesChange} multiple accept={filesTypes === "Vidéo" ? ".mp4,.mov" : "*"} />
                    {isDragActive && (
                        <div className="flex flex-col items-center justify-center absolute top-0 left-0 w-full h-full bg-gray-300/50 z-50">
                            <FontAwesomeIcon icon="cloud" />
                            <Typography variant="h5" className="text-gray-900">
                                Déposez vos fichiers ici
                            </Typography>
                        </div>
                    )}
                </label>
            </div>
            <div className="w-full md:w-1/2 py-2 grow">
                {fileList.length > 0 &&
                    fileList.map((item, index) => (
                        <div className="flex flex-row justify-between px-2 gap-2 -mb-1 -mt-2" key={item.name}>
                            <Typography color="deep-purple">{item.name}</Typography>
                            <FontAwesomeIcon
                                icon="trash"
                                className="text-right cursor-pointer text-capucine-700"
                                onClick={() => removeLineFile(index)}
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
}

