import React, { useEffect } from "react";
import { Button, Card, CardHeader, Input, Spinner, Typography } from "@material-tailwind/react";
import "tw-elements";

import { UserInterface } from "../../components/Interfaces/UserInterface";

import ModalPatient from "./ModalEmployee";
import ModalEdit from "./ModalEdit";
import { Headers } from "./Headers";

import TableWrapper from "../../components/TableWrapper";
import Alerts from "../../components/Alerts";
import PraticerAPI from "../../services/API/clients/PraticerAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../components/Pagination";

/** For Tutorial */
import Joyride from "react-joyride";
import { steps, facticeOrder } from "./Steps";
import TableTutorial from "../../components/TableTutorial";
import TutorialsAPI from "../../services/API/clients/TutorialsAPI";
import BottomTutorial from "../../components/BottomTutorial";

const itemsPerPage = 50;

export default function Patient() {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [users, setUsers] = React.useState<UserInterface[]>([]);
    const [filteredUsers, setFilteredUsers] = React.useState<UserInterface[]>(users);
    const [searchQuery, setSearchQuery] = React.useState("");
    const [openNew, setOpenNew] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState({
        open: false,
        user: {},
    });
    const [currentPage, setCurrentPage] = React.useState(1);
    /** For Tutorial */
    const [step, setStep] = React.useState(0);
    const [playTutorial, setPlayTutorial] = React.useState(false);
    const [run, setRun] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [myObjectEdit, setMyObjectEdit] = React.useState<any>(facticeOrder[0]);
    const [isLoaded, setIsLoaded] = React.useState(false);

    const totalItems = filteredUsers.length;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems - 1);
    const visibleItems = filteredUsers.slice(startIndex, endIndex + 1);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleOpenNew = () => {
        setOpenNew(!openNew);
    };

    const handleOpenEdit = (maj?: boolean) => {
        setOpenEdit({ open: !openEdit.open, user: {} });
        if (maj) handleMAJ();
    };

    useEffect(() => {
        async function fetchData() {
            const resp = await PraticerAPI.users();
            if (resp.status === 200) {
                setUsers(resp.body);
                setFilteredUsers(resp.body);
                setIsLoaded(true);
                setError(false);
            } else {
                setError(true);
                setIsLoaded(true);
            }
        }

        fetchData();
    }, []);

    const handleMAJ = async () => {
        setIsLoaded(false);
        const response = await PraticerAPI.users();
        if (response.status === 200) {
            setUsers(response.body);
            setFilteredUsers(response.body);
            setIsLoaded(true);
            setError(false);
        } else {
            setError(true);
            setIsLoaded(true);
        }
    };

    const handleMAJNew = async () => {
        setIsLoaded(false);
        setOpenNew(false);
        const response = await PraticerAPI.users();
        if (response.status === 200) {
            setUsers(response.body);
            setFilteredUsers(response.body);
            setIsLoaded(true);
            setError(false);
        } else {
            setError(true);
            setIsLoaded(true);
        }
    };

    const handleMAJEdit = async () => {
        setIsLoaded(false);
        setOpenEdit({ open: false, user: {} });
        const response = await PraticerAPI.users();
        if (response.status === 200) {
            setUsers(response.body);
            setFilteredUsers(response.body);
            setIsLoaded(true);
            setError(false);
        } else {
            setError(true);
            setIsLoaded(true);
        }
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        const filteredResults = users.filter(
            (patient: any) => patient.firstname.toLowerCase().includes(query) || patient.lastname.toLowerCase().includes(query)
        );

        setFilteredUsers(filteredResults);
        setCurrentPage(1);
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    /** For Tutorial */

    const handleJoyrideEnd = async (data: any) => {
        setStep(data.index);
        if (data.index === 3) {
            setOpenNew(false);
        }
        if (data.index === 4) {
            setOpenNew(true);
        }
        if (data.index === 5) {
            setOpenNew(false);
            setOpenModal(false);
        }
        if (data.index === 6) {
            setOpenModal(true);
        }
        if (data.index === 7) {
            setOpenModal(false);
        }
        if (data.action === "close") {
            setRun(false);
        }
        if (data.status === "finished" || data.status === "skipped") {
            setPlayTutorial(false);
            const tutorial = await TutorialsAPI.getTutorials();
            if (tutorial.status !== 200) return;
            const myTutorial = tutorial.body;
            const currentPage = "/dash/enterprise";
            const currentTutorial = myTutorial.find((item: any) => item.page === currentPage);
            if (currentTutorial !== undefined) {
                currentTutorial.visited = true;
            } else {
                myTutorial.push({ page: currentPage, visited: true });
            }
            const response = await TutorialsAPI.seeTutorial({ pages: myTutorial });
            if (response.status !== 200) return;
        }
    };

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await TutorialsAPI.getTutorials();
            if (response.status !== 200) return;
            const tutorial = response.body;
            const orderPageVisited = tutorial.find((item: any) => item.page === "/dash/enterprise")?.visited;
            if (orderPageVisited !== undefined) {
                if (orderPageVisited) {
                    setPlayTutorial(false);
                } else {
                    if (width > 768) setPlayTutorial(true);
                    else setPlayTutorial(false);
                }
            }
        };
        fetchData();
    }, []);

    const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setRun(true);
    };

    const endTutorial = async () => {
        setPlayTutorial(false);
        const tutorial = await TutorialsAPI.getTutorials();
        if (tutorial.status !== 200) return;
        const myTutorial = tutorial.body;
        const currentPage = "/dash/enterprise";
        const currentTutorial = myTutorial.find((item: any) => item.page === currentPage);
        if (currentTutorial !== undefined) {
            currentTutorial.visited = true;
        } else {
            myTutorial.push({ page: currentPage, visited: true });
        }
        const response = await TutorialsAPI.seeTutorial({ pages: myTutorial });
        if (response.status !== 200) return;
    };

    return (
        <>
            <BottomTutorial endTutorial={endTutorial} handleClickStart={handleClickStart} playTutorial={playTutorial} step={step} />
            <Joyride
                callback={handleJoyrideEnd}
                continuous
                run={run}
                scrollToFirstStep
                showProgress
                showSkipButton
                steps={steps}
                styles={{
                    options: {
                        zIndex: 99999999,
                    },
                    tooltip: {
                        padding: 20,
                    },
                    beacon: {
                        marginLeft: 5,
                    },
                }}
                locale={{
                    back: "Précédent",
                    close: "Fermer",
                    last: "Terminer",
                    next: "Suivant",
                    skip: "Passer",
                }}
            />
            <div className="mt-12 mb-8 flex flex-col gap-12">
                <ModalPatient open={openNew} setOpen={handleOpenNew} maj={handleMAJNew} />
                <ModalEdit open={openEdit.open} setOpen={handleOpenEdit} maj={handleMAJEdit} user={openEdit.user} />

                <Card>
                    <CardHeader variant="gradient" color="deep-purple" className="mb-4 p-4 flex">
                        <Typography variant="h6" color="white" className={`first-step whereAmIForIt-step ${playTutorial ? "pl-6" : "pl-0"}`}>
                            Utilisateurs
                        </Typography>
                        <Button
                            className="h-auto ml-auto py-0 px-2 color-orange-latoo hover:shadow-xl hover:bg-gray-200 transition duration-200 ease-in-out openModalNew-step"
                            color="white"
                            onClick={() => setOpenNew(true)}
                        >
                            Nouvel Utilisateur
                        </Button>
                    </CardHeader>
                    {!isLoaded ? (
                        <Spinner color="deep-purple" className="mx-auto mb-4 mt-2 w-6 h-6" />
                    ) : error ? (
                        <Alerts message="Un problème est survenu lors de la récupération des utilisateurs" />
                    ) : users === undefined ? (
                        <Alerts message="Chargement des utilisateurs..." />
                    ) : playTutorial ? (
                        <>
                            <div className="w-full p-4 second-step">
                                <Input
                                    type="text"
                                    label="Rechercher un patient ou un praticien"
                                    color="deep-purple"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                />
                            </div>
                            <div className="w-full overflow-auto third-step">
                                <TableTutorial
                                    header={Headers}
                                    data={facticeOrder}
                                    from="employee"
                                    maj={handleMAJ}
                                    open={openModal}
                                    setOpen={setOpenModal}
                                    myObjectEdit={myObjectEdit}
                                    setMyObjectEdit={setMyObjectEdit}
                                />
                            </div>
                        </>
                    ) : users.length === 0 ? (
                        <Alerts message="Aucun utilisateur n'a été créé pour le moment" />
                    ) : (
                        <>
                            <div className="w-full p-4">
                                <Input type="text" label="Rechercher un utilisateur" color="deep-purple" value={searchQuery} onChange={handleSearch} />
                            </div>
                            <div className="w-full overflow-auto">
                                <TableWrapper header={Headers}>
                                    {visibleItems !== undefined &&
                                        visibleItems.length > 0 &&
                                        visibleItems.map((element: any, key: number) => {
                                            const className = `py-3 px-5 ${key === visibleItems.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                                            return (
                                                <React.Fragment key={element.id}>
                                                    <tr className={`example-element-row`}>
                                                        {Headers.map((column: any, i: number) => (
                                                            <td key={column.id} className={className}>
                                                                <Typography variant="small" color="blue-gray" className={i === 0 ? "font-bold" : ""}>
                                                                    {element[column.col]}
                                                                </Typography>
                                                            </td>
                                                        ))}
                                                        <td className={`${className} w-8`} onClick={() => setOpenEdit({ open: true, user: element })}>
                                                            <FontAwesomeIcon icon="pen-to-square" className="cursor-pointer" />
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            );
                                        })}
                                </TableWrapper>
                            </div>
                                <Pagination
                                    handlePageChange={handlePageChange}
                                    itemsPerPage={itemsPerPage}
                                    currentPage={currentPage}
                                    totalItems={totalItems}
                                    startIndex={startIndex}
                                    endIndex={endIndex}
                                />
                        </>
                    )}
                </Card>
            </div>
        </>
    );
}
