import { Card, CardBody, CardHeader, Typography } from "@material-tailwind/react";
import "tw-elements";

import InvoiceList from "./invoice-list/InvoiceList";
import React, { useEffect } from "react";
import { steps } from "./Steps";
import Joyride from "react-joyride";
import TutorialsAPI from "../../services/API/clients/TutorialsAPI";
import BottomTutorial from "../../components/BottomTutorial";

export default function Facturation() {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [step, setStep] = React.useState(0);
    const [playTutorial, setPlayTutorial] = React.useState(false);
    const [run, setRun] = React.useState(false);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleJoyrideEnd = async (data: any) => {
        setStep(data.index)
        if (data.action === "close") {
            setRun(false);
        }
        if (data.status === "finished" || data.status === "skipped") {
            setPlayTutorial(false);
            const tutorial = await TutorialsAPI.getTutorials();
            if (tutorial.status !== 200) return;
            const myTutorial = tutorial.body;
            const currentPage = "/dash/facturation";
            const currentTutorial = myTutorial.find((item: any) => item.page === currentPage);
            if (currentTutorial !== undefined) {
                currentTutorial.visited = true;
            } else {
                myTutorial.push({ page: currentPage, visited: true });
            }
            const response = await TutorialsAPI.seeTutorial({ pages: myTutorial });
            if (response.status !== 200) return;
        }
    };

    React.useEffect(() => {             
        const fetchData = async () => {
            const response = await TutorialsAPI.getTutorials();
            if (response.status !== 200) return;
            const tutorial = response.body;
            const orderPageVisited = tutorial.find((item: any) => item.page === "/dash/facturation")?.visited;
            if (orderPageVisited !== undefined) {
                if (orderPageVisited) {
                    setPlayTutorial(false);
                } else {
                    if (width > 768)
                        setPlayTutorial(true);
                    else 
                        setPlayTutorial(false);
                }
            }
        }
        fetchData();
    }, []);

    const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setRun(true)
    };

    const endTutorial = async () => {
        setPlayTutorial(false);
        const tutorial = await TutorialsAPI.getTutorials();
        if (tutorial.status !== 200) return;
        const myTutorial = tutorial.body;
        const currentPage = "/dash/facturation";
        const currentTutorial = myTutorial.find((item: any) => item.page === currentPage);
        if (currentTutorial !== undefined) {
            currentTutorial.visited = true;
        } else {
            myTutorial.push({ page: currentPage, visited: true });
        }
        const response = await TutorialsAPI.seeTutorial({ pages: myTutorial });
        if (response.status !== 200) return;
    }

    return (
        <>
            <BottomTutorial
                endTutorial={endTutorial}
                handleClickStart={handleClickStart}
                playTutorial={playTutorial}
                step={step}
            />
            <Joyride
                callback={handleJoyrideEnd}
                continuous
                run={run}
                scrollToFirstStep
                showProgress
                showSkipButton
                steps={steps}
                styles={{
                    options: {
                        zIndex: 99999999,
                    },
                    tooltip: {
                        padding: 20,
                    },
                    beacon: {
                        marginLeft: 5,
                    },
                }}
                locale={{
                    back: "Précédent",
                    close: "Fermer",
                    last: "Terminer",
                    next: "Suivant",
                    skip: "Passer",
                }}
            />
            <div className="mt-12 mb-8 flex flex-col gap-12">
                <Card>
                    <CardHeader
                        variant="gradient"
                        color={"deep-purple"}
                        className="mb-4 p-4 flex"
                    >
                        <Typography variant="h6" color="white" className={`first-step ${playTutorial ? "pl-6" : "pl-0"}`}>
                            Facturation
                        </Typography>
                    </CardHeader>
                    <CardBody>
                        <div className="z-0 relative">
                            <InvoiceList playTutorial={playTutorial} />
                        </div>
                    </CardBody>
                </Card>
            </div>
        </>
    );
}
