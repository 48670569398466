import APIClient from "../APIClients";

export default class Patient {
    public static async patient() {
        return APIClient.client.get("/company/patient/all");
    }

    public static async newPatient(data: {}) {
        return APIClient.client.post("/company/patient", data);
    }

    public static async getPatient(id: string) {
        return APIClient.client.get(`/company/patient?id=${id}`);
    }

    public static async deletePatient(id: string) {
        return APIClient.client.delete(`/company/patient?id=${id}`);
    }

    public static async putPatient(id: string, data: {}) {
        return APIClient.client.put(`/company/patient?id=${id}`, data);
    }
}
