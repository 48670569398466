export const steps = [
    {
        target: "body",
        content: "Sur cette page, vous pouvez créer, modifier ou supprimer vos collaborateurs.",
        placement: "center" as const,
    },
    {
        target: ".second-step",
        content: "Utilisez la barre de recherche pour trouver rapidement un collaborateur par son prénom ou son nom.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    {
        target: ".third-step",
        content: "Voici la liste complète de vos collaborateurs avec leurs informations essentielles : prénom, nom, civilité, e-mail, profession, téléphone et portable.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    {
        target: ".openModalNew-step",
        content: "Ce bouton vous permet d'accéder au formulaire de création d'un nouveau collaborateur.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    {
        target: ".whereAmIForIt-step",
        content: "Une fois le formulaire rempli, cliquez ici pour créer le collaborateur.",
        placementBeacon: "left" as const,
        placement: "center" as const,
    },
    {
        target: ".editPatient-step",
        content: "Si une erreur est commise, vous pouvez modifier les informations du collaborateur en cliquant ici.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    {
        target: ".whereAmIForIt-step",
        content: "Cliquez sur le bouton 'Supprimer' pour supprimer un patient, et pour valider les modifications, cliquez sur le bouton 'Modifier'.",
        placementBeacon: "left-start" as const,
        placement: "center" as const,
    },
    {
        target: ".patient_step",
        content: "Cliquez ici pour passer à l'étape suivante.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
];


export const facticeOrder = [
    {
        id: "1",
        firstname: "Jean",
        lastname: "Dupont",
        civility: "Monsieur",
        email: "jean.dupont@test.com",
        profession: "Dentiste",
        phone: "+33123456789",
        mobilePhone: "+33612345678",
    },
    {
        id: "2",
        firstname: "Jeanne",
        lastname: "Laurent",
        civility: "Madame",
        email: "jeanne.laurent@test.com",
        profession: "Assistante dentaire",
        phone: "+33123456789",
        mobilePhone: "+33612345678",
    },
    {
        id: "3",
        firstname: "Jean",
        lastname: "Xavier",
        civility: "Autre",
        email: "jean.xavier@test.com",
        profession: "Secrétaire",
        phone: "+33123456789",
        mobilePhone: "+33612345678",
    }
]
