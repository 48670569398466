import "tailwindcss/tailwind.css";
import { Button, Typography } from "@material-tailwind/react";

import { getAllowedRoutes } from "../../services/routes";
import NavLinks from "./NavLink";
import { Storage } from "../../services/storage";

import Images from "../../styles/LIO.png";
import React, { useEffect } from "react";
import NotificationAPI from "../../services/API/clients/NotificationAPI";
import TutorialsAPI from "../../services/API/clients/TutorialsAPI";
import { XCircleIcon } from "@heroicons/react/24/outline";

interface AsideProps {
    open: boolean;
    handleOpen: () => void;
}

export default function Aside({ open, handleOpen }: Readonly<AsideProps>): React.JSX.Element {
    const roles = Storage.getRoles();
    const [notifications, setNotifications] = React.useState([]);
    const [tutorials, setTutorials] = React.useState([]);
    const asideRef = React.useRef<HTMLDivElement>(null);

    const filteredRoutes = roles ? getAllowedRoutes(roles) : [];

    const fetchTutorial = async () => {
        const res = await TutorialsAPI.getTutorials();
        if (res.status === 200) {
            setTutorials(res.body);
        }
    };

    const fetchData = async () => {
        const res = await NotificationAPI.getNotifs();
        if (res.status === 200) {
            setNotifications(res.body);
        }
    };

    useEffect(() => {
        fetchData();
        fetchTutorial();
    }, []);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (asideRef.current && !asideRef.current.contains(event.target as Node)) {
                handleOpen();
            }
        }
        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open, handleOpen]);

    return (
        <aside
            ref={asideRef}
            className={`bg-white shadow-lg ${
                open ? "translate-x-0" : "-translate-x-80"
            } fixed inset-0 z-40 my-4 ml-4 h-[calc(100vh-32px)] w-72 rounded-xl transition-transform duration-300 xl:translate-x-0 overflow-y-auto scrollPretty`}
        >
            <div className={`absolute top-0 right-0 grid xl:hidden ${!open && "hidden"} align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-lime-500 hover:bg-lime-500/10 active:bg-lime-500/30 absolute top-0 right-0 flex justify-center items-center`} onClick={() => handleOpen()}>
                <XCircleIcon strokeWidth={3} className="h-6 w-6 text-capucine-700" />
            </div>
            <a href="/dash/order">
                <img src={Images} className="w-1/4 mx-auto mb-6" alt="logo_lio" />
            </a>
            <div className="m-4 relative overflow-auto" style={{ height: "calc(100% - 180px)"}}>
                
                {filteredRoutes.map(
                    ({ baseUrl, pages, title, shownInSidebar }, key) =>
                        shownInSidebar && (
                            <div key={title}>
                                {title && (
                                    <Typography variant="small" color="lime" className="mx-3.5 mt-4 mb-2 font-black uppercase opacity-75">
                                        {title}
                                    </Typography>
                                )}
                                <ul className={`mb-4 flex flex-col gap-4 ${title === "Gestion" && "otherTutorials-step"}`}>
                                    {pages
                                        .filter((p) => p.display)
                                        .map(({ display, path }) => (
                                            <li key={display ? display.name : "randomName"}>
                                                <NavLinks
                                                    layout={baseUrl}
                                                    path={`/${path}`}
                                                    handleOpen={handleOpen}
                                                    icon={display?.icon}
                                                    name={display?.name ?? path}
                                                    notifications={notifications}
                                                    fetchData={fetchData}
                                                    tutorials={tutorials}
                                                    fetchTutorials={fetchTutorial}
                                                />
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        )
                )}
            </div>
            <div className="fixed w-full text-center px-4 opacity-70">
                <div className="align-middle select-none font-sans font-bold text-center uppercase transition-all text-xs py-3 px-6 rounded-lg border border-deep-purple-500 text-deep-purple-500 w-full">
                    Problème technique ? <br />
                    09 66 96 06 29
                </div>
            </div>
        </aside>
    );
}
