import { Dialog } from "@headlessui/react";
import { Alert, Card, IconButton, Input, Textarea, Typography } from "@material-tailwind/react";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import PromptPopup from "../../GroupPrompt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function VerifClientTutorial({
    setOpen,
    data,
}: Readonly<{ setOpen: (open: string, arg0?: boolean) => void; data: any; handleStatus: any }>) {
    const cancelButtonRef = useRef(null);
    const [order, setOrder] = React.useState<any>({});
    const files: any = {"uploaded":{"id":8211,"name":"Client","description":"Fichiers téléversés par le client","createdAt":"2024-02-01T23:28:08.000Z","updatedAt":"2024-02-01T23:28:08.000Z","files":[{"id":468,"public":true,"name":"pistachios-3450670_1280.jpg","description":"{\"description\":\"cGlzdGFjaGlvcy0zNDUwNjcwXzEyODAuanBn\"}","type":"jpg","size":214038,"uploadedAt":"2024-02-01T23:28:11.000Z","updatedAt":"2024-02-01T23:28:11.000Z"}]},"delivery":{"id":8212,"name":"Rendu","description":"Fichiers rendus disponibles pour le client","createdAt":"2024-02-01T23:28:08.000Z","updatedAt":"2024-02-01T23:28:08.000Z","files":[]}}

    useEffect(() => {
        setOrder(data);
    }, [data]);

    return (
        <>
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-left sm:ml-4 sm:mt-0">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 border-b pb-2">
                            Modification de {data?.code}
                        </Dialog.Title>
                        <div className="flex flex-row gap-4 justify-center">
                            <div className="flex flex-row flex-wrap mt-2 gap-2 w-1/3 items-start justify-start content-start sideInfoGenrales-step">
                                <Alert className="my-2" color="deep-purple"><b>Echéance : </b> {moment(order.deliveryTarget).format("DD.MM.YYYY")}</Alert>
                                <Input label="Patient" type="text" color="blue-gray" value={data.practicerFullName} className="w-96" disabled />
                                <Input label="Client" type="text" color="blue-gray" value={data.patientFullName} className="w-96" disabled />
                                <Input label="Laboratoire" type="text" color="blue-gray" value={data.companyName} className="w-96" disabled />
                                <Input
                                    label="Date de création"
                                    type="text"
                                    color="blue-gray"
                                    value={moment(data.createdAt).format("DD/MM/YYYY")}
                                    className="w-96"
                                    disabled
                                />
                                <Textarea label="Commentaire" color="blue-gray" value={data.comment} className="w-96" disabled />
                                <div className="w-full h-auto rounded-md px-4 pt-2 filesForInfo-step" style={{ backgroundColor: "#eceff1" }}>
                                    {files !== undefined && Object.keys(files).length > 0 ? (
                                        <ul className="mb-1">
                                            {Object.keys(files).map((key: any) => (
                                                <li key={files[key].id } className="mb-1">
                                                    <Typography variant="small" className="font-normal text-blue-gray-700 text-base">
                                                        • {files[key].name}{" "}
                                                        {files[key].files.length > 0 && (
                                                            <IconButton size="sm" className="text-xs ml-1" color="lime">
                                                                <FontAwesomeIcon icon="download" className="text-white" />
                                                            </IconButton>
                                                        )}
                                                    </Typography>
                                                    <ul className="mt-0.5 ml-6">
                                                        {files[key].files.length === 0 ? (
                                                            <li>
                                                                <Typography variant="small" className="font-normal text-blue-gray-700 text-sm">
                                                                    Aucun fichier
                                                                </Typography>
                                                            </li>
                                                        ) : (
                                                            files[key].files.map((fichier: { id: string; name: string }) => (
                                                                <li key={fichier.id}>
                                                                    <Typography
                                                                        variant="small"
                                                                        className="font-normal text-blue-gray-700 text-sm flex items-center"
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon="download"
                                                                            className="bg-royal-900 hover:bg-royal-800 p-2 text-xs text-white rounded-lg mr-2 mb-0.5 cursor-pointer"
                                                                        />
                                                                        {fichier.name}
                                                                    </Typography>
                                                                </li>
                                                            ))
                                                        )}
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <Input color="blue-gray" className="font-normal text-left pt-2 pl-2" value="Aucun fichiers" disabled />
                                    )}
                                </div>
                                <div className="flex items-center justify-center w-full my-2 addFiles-step">
                                    <label
                                        htmlFor="dropzone-file"
                                        className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                    >
                                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                            <svg
                                                className="w-10 h-10 mb-3 text-gray-400"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                                ></path>
                                            </svg>
                                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                Ajouter des <span className="font-semibold">fichiers</span>
                                            </p>
                                        </div>
                                        <input id="dropzone-file" type="file" className="hidden" multiple />
                                    </label>
                                </div>
                                <div className="flex items-center justify-center w-full mb-2">
                                    <label
                                        htmlFor="dropzone-file-2"
                                        className="flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                    >
                                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                            <svg
                                                className="w-7 h-7 mb-1 text-gray-400"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                                ></path>
                                            </svg>
                                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                Ajouter des fichiers <span className="font-semibold">LIO MOVE</span>
                                            </p>
                                        </div>
                                        <input id="dropzone-file-2" type="file" className="hidden" disabled multiple />
                                    </label>
                                </div>
                            </div>
                            <div className="overflow-x-auto pt-2 w-2/3">
                                <Card className="overflow-scroll h-auto w-full listCommand-step">
                                    <table className="w-full table-auto text-left">
                                        <thead>
                                            <tr>
                                                {["Identifiant", "Dents", "Code produit", "Prix", "Description"].map((head) => (
                                                    <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                        <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                                                            {head}
                                                        </Typography>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.products.map((product: any, index: number) => {
                                                const isLast = index === data.products.length - 1;
                                                const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                                                return (
                                                    <tr key={product.productRef.code}>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                {data?.code}-{index}
                                                            </Typography>
                                                        </td>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal min-w-[100px]">
                                                                {product.teeth.join(", ")}
                                                            </Typography>
                                                        </td>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal whitespace-nowrap">
                                                                {product.productRef.code}
                                                            </Typography>
                                                        </td>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal">
                                                                {product.productRef.price}
                                                            </Typography>
                                                        </td>
                                                        <td className={classes}>
                                                            <Typography variant="small" color="blue-gray" className="font-normal whitespace-nowrap">
                                                                {product.productRef.description}
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                {/* <PromptPopup color="green-600" colorHover="green-500" text="Fin de Vérification" recupComment={endTaskTrue} title="Fin de Vérification" buttonText="Terminer" /> */}
                <button
                    type="button"
                    className="orderOk-step inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                >
                    {"Fin de vérification"}
                </button>
                <div className="askModif-step">
                    <PromptPopup
                        color="red-600"
                        colorHover="red-500"
                        text="Demande de modification"
                        recupComment={() => console.log("hello")}
                        title="Demande de modification"
                        buttonText="Demander à modifier"
                        isLoading={false}
                    />
                </div>
                <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen("allInOne")}
                    ref={cancelButtonRef}
                >
                    Fermer
                </button>
            </div>
        </>
    );
}
