export const Header = [
    {
        id: "Identifiant",
        col: "code",
    },
    {
        id: "Patient",
        col: "patientFullName",
    },
    {
        id: "Praticien",
        col: "practicerFullName",
    },
    {
        id: "Statut",
        col: "step",
    },
];

export const HeaderExtends = [
    {
        id: "ID",
        col: "code",
    },
    {
        id: "Dents",
        col: "teeth",
    },
    {
        id: "Code",
        col: "productRef.code",
    },
    {
        id: "Description",
        col: "productRef.description",
    },
    {
        id: "Prix",
        col: "productRef.price",
    },
];
