export const Headers = [
    {
        id: "Prénom",
        col: "firstname",
    },
    {
        id: "Nom",
        col: "lastname",
    },
    {
        id: "Civilité",
        col: "civility",
    },
];
