import { useEffect, useState } from "react";
import FacturationApi from "../../../services/API/clients/FacturationAPI";
import { Invoice } from "../dto/invoice.dto";
import "./style.css";

import InvoiceListTab from "./Tabs";
import { facticeOrder } from "../Steps";

interface Invoices {
    loading: boolean;
    invoices: Invoice[];
}

export default function InvoiceList({ playTutorial } : Readonly<{ playTutorial: boolean }>) {
    const [invoices, setInvoices] = useState<Invoices>({
        loading: false,
        invoices: []
    })

    const handleSearch = async () => {

        const invoice = FacturationApi.findInvoice();

        Promise.all([invoice]).then((results) => {
            if (results.some(r => r.status !== 200))
                return
            setInvoices({
                loading: false,
                invoices: results[0].body
            })
        })

        setInvoices({
            loading: true,
            invoices: []
        })
    }

    useEffect(() => {
        handleSearch()
    }, [])

    return (
        <>
            {playTutorial ? (
                <div className="allTable-step">
                <InvoiceListTab
                    isLoading={false}
                    invoices={facticeOrder}
                    value='invoices'
                />
                </div>
            ) : (
                <InvoiceListTab
                    isLoading={invoices.loading}
                    invoices={invoices.invoices}
                    value='invoices'
                />
            )}
        </>
    )
}