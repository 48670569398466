import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Option, Select } from "@material-tailwind/react";
import React from "react";
import PasswordStrengthBar from "react-password-strength-bar";

export default function SecondPart({ status, formData, setFormData }: Readonly<{ status: { message: string[] }; formData: any; setFormData: any }>) {
    const [isReveal, setIsReveal] = React.useState<boolean[]>([false]);
    const addUser = () => {
        const newPraticer = {
            name: "",
            firstname: "",
            profession: "",
            mail: "",
            phone: "",
            fixePhone: "",
            civility: "Monsieur",
            password: "",
        };
        setFormData({ ...formData, praticers: [...formData.praticers, newPraticer] });
    };

    const deleteUser = (index: number) => {
        const userToRemove = formData.praticers[index];
        const newPraticers = formData.praticers.filter((praticer: any) => praticer !== userToRemove);
        setFormData({ ...formData, praticers: newPraticers });
    };

    const revealPassword = (index: number) => () => {
        const newReveal = [...isReveal];
        newReveal[index] = !newReveal[index];
        setIsReveal(newReveal);
    };

    const handleChange = (e: any, index: number) => {
        const newPraticers = [...formData.praticers];
        newPraticers[index][e.target.name] = e.target.value;
        setFormData({ ...formData, praticers: newPraticers });
    };

    const isInError = (index: number, name: string) => {
        const error = status.message.find((error: string) => error.includes(`praticers.${index}.${name}`));
        return error ? true : false;
    };

    return (
        <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7 flex flex-col gap-4 flex-wrap">
            <h4 className="w-full text-royal-900 border-b border-b-capucine-700 pb-2">Praticiens</h4>
            {formData.praticers.map((praticer: any, index: number) => (
                <div className="flex flex-col gap-4 relative bg-blue-gray-50/50 p-4 rounded-xl w-full" key={praticer.lastname + praticer.lastname}>
                    {formData.praticers.length > 1 && (
                        <button
                            type="button"
                            onClick={() => deleteUser(index)}
                            className="absolute -top-2 -right-2 flex flex-row gap-4 p-2 rounded-full bg-capucine-500"
                        >
                            <FontAwesomeIcon icon="x" className="text-white cursor-pointer w-2 h-2" />
                        </button>
                    )}
                    <div className="flex flex-col gap-4">
                        <div className="w-full flex flex-col lg:flex-row gap-4">
                            <div className="relative w-full lg:w-1/3 h-10">
                                <Input
                                    value={praticer.firstname}
                                    onChange={(e) => handleChange(e, index)}
                                    name="firstname"
                                    color="deep-purple"
                                    label="Prénom"
                                    required
                                    containerProps={{ className: "min-w-[72px]" }}
                                />
                            </div>
                            <div className="relative w-full lg:w-1/3 h-10">
                                <Input
                                    value={praticer.name}
                                    onChange={(e) => handleChange(e, index)}
                                    name="name"
                                    color="deep-purple"
                                    label="Nom"
                                    required
                                    containerProps={{ className: "min-w-[72px]" }}
                                />
                            </div>
                            <div className="relative w-full lg:w-1/3 h-10">
                                <Input
                                    value={praticer.profession}
                                    onChange={(e) => handleChange(e, index)}
                                    name="profession"
                                    color="deep-purple"
                                    label="Profession"
                                    required
                                    containerProps={{ className: "min-w-[72px]" }}
                                />
                            </div>
                        </div>
                        <div className="w-full flex flex-col lg:flex-row gap-4">
                            <div className="relative w-full lg:w-1/3 h-10">
                                <Input
                                    type="email"
                                    value={praticer.mail}
                                    onChange={(e) => handleChange(e, index)}
                                    name="mail"
                                    color="deep-purple"
                                    label="Adresse mail"
                                    required
                                    error={isInError(index, "mail")}
                                    containerProps={{ className: "min-w-[72px]" }}
                                />
                            </div>
                            <div className="relative w-full lg:w-1/3 h-10">
                                <Input
                                    type="tel"
                                    value={praticer.phone}
                                    onChange={(e) => handleChange(e, index)}
                                    name="phone"
                                    color="deep-purple"
                                    label="Téléphone mobile"
                                    required
                                    error={isInError(index, "phone")}
                                    containerProps={{ className: "min-w-[72px]" }}
                                />
                            </div>
                            <div className="relative w-full lg:w-1/3 h-10">
                                <Input
                                    type="tel"
                                    value={praticer.fixePhone}
                                    onChange={(e) => handleChange(e, index)}
                                    name="fixePhone"
                                    color="deep-purple"
                                    label="Téléphone fixe"
                                    error={isInError(index, "fixePhone")}
                                    containerProps={{ className: "min-w-[72px]" }}
                                />
                            </div>
                        </div>
                        <div className="w-full flex flex-col lg:flex-row gap-4">
                            <div className="relative w-full lg:w-1/2 h-10">
                                <Select value={praticer.civility} color="deep-purple" label="Civilité" containerProps={{ className: "min-w-[72px]" }}>
                                    <Option value="Monsieur">Monsieur</Option>
                                    <Option value="Madame">Madame</Option>
                                    <Option value="Autre">Autre</Option>
                                </Select>
                            </div>
                            <div className="relative w-full lg:w-1/2 h-10">
                                <Input
                                    type={isReveal[index] ? "text" : "password"}
                                    value={praticer.password}
                                    name="password"
                                    onChange={(e) => handleChange(e, index)}
                                    containerProps={{ className: "min-w-[72px]" }}
                                    color="deep-purple"
                                    label="Mot de passe"
                                    required
                                />
                                <FontAwesomeIcon
                                    onClick={revealPassword(index)}
                                    icon="eye"
                                    className="absolute top-3.5 right-2 text-sm text-royal-900 cursor-pointer"
                                />
                            </div>
                        </div>
                        <div className="w-full flex flex-col lg:flex-row gap-4 -mt-4 -mb-2">
                            <div className="w-full lg:w-1/2"></div>
                            <div className="w-full lg:w-1/2">
                                <PasswordStrengthBar password={praticer.password} />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <button
                onClick={addUser}
                type="button"
                className="text-roayl-900 text-sm cursor-pointer"
                data-dashlane-rid="59fc09010024fd27"
                data-dashlane-label="true"
                data-form-type="other"
            >
                <FontAwesomeIcon icon="plus" />
                <span className="ml-1">Ajouter un praticien</span>
            </button>
        </div>
    );
}
