import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Chip, IconButton } from "@material-tailwind/react";
import moment from "moment";
import { Invoice } from "../dto/invoice.dto";
import "./style.css";
import FacturationApi from "../../../services/API/clients/FacturationAPI";

interface InvoiceListElementProps {
    invoice: Invoice;
    onSelect?: () => void;
    onDeselect?: () => void;
    selected?: boolean;
    onDelete?: (id: string) => void;
    onValidate?: (id: string) => void;
    editable?: boolean;

}

function formatInvoiceCode(data: Invoice) {
    return `LIO FACT-${moment(data.date).format('YYYYMMDD')}-${String(
        data.code,
    ).padStart(5, '0')}`;
}

export default function InvoiceListElement({ invoice, onSelect, onDeselect, selected }: InvoiceListElementProps) {
    const handlePayment = async () => {
        const data = {
            invoiceId: invoice.id,
        }
        const resp = await FacturationApi.getPayment(data)
        if (resp.status === 200) {
            const url = resp.body.url
            window.open(url, '_blank')
        }
    }

    const isDisabled = () => {
        if (invoice.payment.paymentStatus === "UNPAID") {
            return false
        } else if (invoice.payment.paymentStatus === "PENDING") {
            return false
        } else if (invoice.payment.paymentStatus === "WRONG_AMOUNT") {
            return false
        } else if (invoice.payment.paymentStatus === "REFUND") {
            return true
        } else {
            return true
        }
    }

    const colorButton = () => {
        if (invoice.payment.paymentStatus === "UNPAID") {
            return "red"
        } else if (invoice.payment.paymentStatus === "PENDING") {
            return "orange"
        } else if (invoice.payment.paymentStatus === "WRONG_AMOUNT") {
            return "yellow"
        } else if (invoice.payment.paymentStatus === "REFUND") {
            return "yellow"
        } else {
            return "green"
        }
    }

    const textButton = () => {
        if (invoice.payment.paymentStatus === "UNPAID") {
            return "A payer"
        } else if (invoice.payment.paymentStatus === "PENDING") {
            return "En attente"
        } else if (invoice.payment.paymentStatus === "WRONG_AMOUNT") {
            return "Montant incorrect"
        } else if (invoice.payment.paymentStatus === "REFUND") {
            return "Remboursé"
        } else {
            return "Payé"
        }
    }

    return (
        <div className="children-full">
            <Checkbox
                id={`checkbox-${invoice.id}`}
                color="deep-purple"
                labelProps={{
                    className: "w-full",
                }}
                checked={selected}
                onChange={() => {
                    if (selected) {
                        onDeselect && onDeselect()
                    } else {
                        onSelect && onSelect()
                    }
                }}
                label={
                    <div className="flex items-center justify-between w-full text-sm">
                        <span className="w-fit whitespace-nowrap id-step">
                            {formatInvoiceCode(invoice)}
                        </span>
                        <span className="ml-2 w-1/6 line-clamp-1 overflow-hidden text-ellipsis titleInvoice-step"><b>{invoice.title}</b></span>
                        <span className="w-fit praticienInvoice-step mx-4 md:mx-0 whitespace-nowrap md:whitespace-normal">{invoice.practicer.fullName}<br /> {"("}{invoice.practicer.company.name}{")"}</span>
                        <div className="flex gap-1 w-fit mx-2 md:mx-0">
                            <Chip color="deep-purple" className="dateInvoice-step" value={moment(invoice.date).format('DD/MM/YYYY')} />
                            {invoice.hasVat && <Chip color="deep-purple" className="tvaInvoice-step" value="TVA" variant="outlined" />}
                        </div>
                        <div className="flex w-fit gap-3 md:gap-1 mx-2 md:mx-0 whitespace-nowrap md:whitespace-normal">
                            <Button variant="outlined" color={colorButton()} onClick={() => handlePayment()} className={`mx-1 ${invoice.payment.paymentStatus === "PAID" && "paidInvoice-step"} ${invoice.payment.paymentStatus === "UNPAID" && "unPaidInvoice-step"} ${invoice.payment.paymentStatus === "PENDING" && "waitPaidInvoice-step"}`} disabled={isDisabled()}>
                                {textButton()}
                            </Button>
                            <IconButton className="consultInvoice-step" variant="outlined" color="deep-purple" onClick={() => window.open(`facturation/document/${invoice.id}`, '_blank')}>
                                <FontAwesomeIcon icon='file' className="consultInvoice-step" />
                            </IconButton>
                        </div>
                    </div>
                }
            />
        </div>
    )
}
