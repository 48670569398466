import React, { useEffect } from "react";
import { Input, Radio } from "@material-tailwind/react";
import Select from "react-select";

import { Storage } from "../../../services/storage";

import Patient from "../../../services/API/clients/PatientAPI";
import PraticerAPI from "../../../services/API/clients/PraticerAPI";

const civilites = [
    { label: "Monsieur", value: "MAN" },
    { label: "Madame", value: "WOMAN" },
    { label: "Autre", value: "OTHER" },
];

export default function SelectPatient({
    order,
    setOrder,
    handleSelectChange,
    radio,
    setRadio,
    loading,
}: Readonly<{ order: any; setOrder: any; handleSelectChange: any; radio: string; setRadio: Function; loading: boolean }>) {
    const [patients, setPatients] = React.useState([]);
    const [praticers, setPraticers] = React.useState([]);
    const [roles, setRoles] = React.useState("");

    const checkRoles = async (roles: string[]) => {
        if (roles.includes("COMPANY")) {
            setOrder({ ...order, companyId: Storage.getCompanyId() ?? "" });
            const responsePraticer = await PraticerAPI.users();
            if (responsePraticer.status === 200) {
                const transformedData = responsePraticer.body.map((item: { id: string; firstname: string; lastname: string }) => ({
                    value: item.id,
                    label: `${item.firstname} ${item.lastname}`,
                }));
                setPraticers(transformedData);
            }
            setRoles("COMPANY");
        } else if (roles.includes("PRACTICER")) {
            const responsePraticer = await PraticerAPI.getPraticer(Storage.getPracticerId() ?? "");
            if (responsePraticer.status === 200) {
                const transformedData: any = [];
                const myObject = { value: responsePraticer.body.id, label: `${responsePraticer.body.firstname} ${responsePraticer.body.lastname}` };
                transformedData.push(myObject);
                setPraticers(transformedData);
            }
            setOrder({ ...order, practicerId: Storage.getPracticerId() ?? "", companyId: Storage.getCompanyId() ?? "" });
            setRoles("PRACTICER");
        } else return;
    };

    useEffect(() => {
        async function fetchData() {
            const responsePatient = await Patient.patient();
            if (responsePatient.status === 200) {
                const transformedData = responsePatient.body.map((item: { id: string; firstname: string; lastname: string }) => ({
                    value: item.id,
                    label: `${item.firstname} ${item.lastname}`,
                }));
                setPatients(transformedData);
            }

            const roles = Storage.getRoles();
            checkRoles(roles);
        }
        fetchData();
    }, []);

    const customStyles = {
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        control: (baseStyles: any, state: { isFocused: any, isDisabled: any }) => ({
            ...baseStyles,
            boxShadow: state.isFocused ? "0 0 0 1px rgb(0, 51, 107)" : "",
            borderColor: state.isFocused ? "rgb(0, 51, 107)" : state.isDisabled ? "rgb(236, 239, 241)" : "rgb(176, 190, 197)",
            backgroundColor: state.isDisabled ? "rgb(236, 239, 241)" : "transparent",
            color: "rgb(96, 125, 139)",
            "&:hover": {
                borderColor: state.isFocused ? "rgb(0, 51, 107)" : "rgb(176, 190, 197)",
            },
            fontSize: "14px"
        }),
        placeholder: (base: any) => ({
            ...base,
            color: "rgb(96, 125, 139)",
            fontSize: "14px"
        }),
        singleValue: (base: any) => ({
            ...base,
            color: "rgb(96, 125, 139)",
            fontSize: "14px"
        }),
    };

    const handleInputChange = (event: { target: { name: any; value: any } }) => {
        const { name, value } = event.target;
        setOrder({ ...order, [name]: value });
    };

    const handleRadio = (value: string) => {
        setRadio(value);
    };

    return (
        <div className="w-full md:w-1/2 flex flex-col gap-2 justify-center second-step">
            <Select
                placeholder="Sélectionner un praticien"
                name="practicerId"
                className="w-full second-step"
                options={praticers}
                onChange={(event) => handleSelectChange(event, "practicerId")}
                menuPortalTarget={document.body}
                styles={customStyles}
                value={praticers.filter((praticer: any) => order.practicerId === praticer.value)}
                menuPosition="fixed"
                isDisabled={roles === "PRACTICER" || loading}
                required
            />
            <div className="flex flex-row gap-2 third-step">
                <Radio
                    value="existing"
                    id="existing"
                    color="deep-purple"
                    label="Patient déjà existant"
                    onChange={() => handleRadio("existing")}
                    checked={radio === "existing"}
                    disabled={loading}
                />
                <Radio
                    value="new"
                    id="new"
                    color="deep-purple"
                    label="Nouveau patient"
                    onChange={() => handleRadio("new")}
                    checked={radio === "new"}
                    disabled={loading}
                />
            </div>
            {radio === "new" ? (
                <div className="flex flex-col gap-2 fourth-step">
                    <Input
                        label="Nom du Patient"
                        type="text"
                        color="deep-purple"
                        name="patientName"
                        id="patientName"
                        value={order.patientName}
                        onChange={handleInputChange}
                        required
                        disabled={loading}
                    />
                    <Input
                        label="Prénom du Patient"
                        type="text"
                        color="deep-purple"
                        name="patientFirstname"
                        id="patientFirstname"
                        value={order.patientFirstname}
                        onChange={handleInputChange}
                        required
                        disabled={loading}
                    />
                    <Select
                        placeholder="Civilité"
                        name="roles"
                        className="w-full"
                        options={civilites}
                        onChange={(event) => handleSelectChange(event, "civility")}
                        menuPortalTarget={document.body}
                        styles={customStyles}
                        value={civilites.filter((civilite) => order.patientCivility === civilite.value)}
                        menuPosition="fixed"
                        required
                        isDisabled={loading}
                    />
                </div>
            ) : (
                <Select
                    placeholder="Sélectionner un patient"
                    name="patientId"
                    className="w-full"
                    options={patients}
                    onChange={(event) => handleSelectChange(event, "patientId")}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                    value={patients.filter((patient: any) => order.patientId === patient.value)}
                    menuPosition="fixed"
                    required
                    isDisabled={loading}
                />
            )}
        </div>
    );
}
