import moment from "moment";
import { useEffect, useState } from "react";
import { Invoice } from "../dto/invoice.dto";
import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InvoiceListMonth, { MonthInvoices } from "./Month";

import 'moment/locale/fr'
moment.locale('fr')

function Loader() {
    return (
        <div className="flex justify-center">
            <FontAwesomeIcon icon="spinner" className="animate-spin" />
        </div>
    )
}

interface InvoiceListTabProps {
    isLoading: boolean;
    invoices: Invoice[];
    value: string;
    onDelete?: (id: string) => void;
    onGroup?: (ids: string[]) => void;
    onValidate?: (id: string) => void;
    editable?: boolean;
}

export default function InvoiceListTab({ isLoading, invoices, value, onDelete, onGroup, onValidate, editable }: Readonly<InvoiceListTabProps>) {
    const [selectedInvoices, setSelectedInvoices] = useState<string[]>([])

    const selectInvoice = (id: string) => {
        setSelectedInvoices([...selectedInvoices, id])
    }

    const unselectInvoice = (id: string) => {
        setSelectedInvoices(selectedInvoices.filter(e => e !== id))
    }

    const unselectAll = () => {
        setSelectedInvoices([])
    }

    useEffect(() => {
        unselectAll()
    }, [invoices])

    const months: MonthInvoices[] = []

    invoices.forEach(e => {
        const month = moment(e.date).format('MMMM YYYY').toUpperCase()
        const monthInvoices = months.find(m => m.month === month)
        if (monthInvoices) {
            monthInvoices.invoices.push(e)
        } else {
            months.push({
                month,
                invoices: [e]
            })
        }
    })


    return <>
        {isLoading
            ? <Loader />
            : months.map(m => <InvoiceListMonth
                key={m.month}
                month={m}
                onSelect={selectInvoice}
                onUnselect={unselectInvoice}
                selectedInvoices={selectedInvoices}
                onDelete={onDelete}
                onValidate={onValidate}
                editable={editable}
            />)}
        </>
}