import APIClient from "../APIClients";

export default class NotificationAPI {
    public static async getNotifs() {
        return APIClient.client.get("/notification");
    }

    public static async doneNotif(ids: {}) {
        return APIClient.client.post("/notification/done", ids);
    }
}
