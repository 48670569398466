export default function StripeFail() {
    return (
        
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center overflow-hidden">
            <div className="relative py-3 mx-auto w-full md:w-1/2">
                <div className="absolute inset-0 bg-gradient-to-r from-red-700 to-red-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                <div className="relative px-2 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-14 mx-auto">
                    <h1 className="text-2xl text-royal-900 font-semibold">Paiement refusé</h1>
                    <p className="text-royal-900 font-semibold mt-3">Le paiement n'a pas abouti, veuillez réessayer plus tard. Vous pouvez fermer cette page</p>
                </div>
            </div>
        </div>
    )
}