import { Button, Input, Typography } from "@material-tailwind/react";
import HandleErrors from "../../../components/HandleErrors";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ThirdStep({
    setFormData,
    setActiveStep,
    status,
    formData,
}: Readonly<{ setFormData: any; setActiveStep: any; status: { message: string[] }; formData: any; whichAAccount: boolean; setWhichAAccount: any }>) {
    const [isReveal, setIsReveal] = React.useState<boolean>(false);
    const formInput = [
        {
            name: "email",
            type: "email",
            label: "Adresse Mail",
            required: true
        },
        {
            name: "password",
            type: "password",
            label: "Mot de passe",
            required: true
        },
        {
            name: "mobilePhone",
            type: "tel",
            label: "Téléphone portable",
            required: true,
        },
        {
            name: "phone",
            type: "tel",
            label: "Téléphone",
            required: false,
        },
        {
            name: "mainContactEmail",
            type: "email",
            label: "Adresse Mail du contact principal",
            required: true
        },
        {
            name: "mainContactFirstname",
            type: "text",
            label: "Prénom du contact principal",
            required: true
        },
        {
            name: "mainContactLastname",
            type: "text",
            label: "Nom du contact principal",
            required: true
        },
        {
            name: "mainContactProfession",
            type: "text",
            label: "Profession du contact principal",
            required: true
        },
        {
            name: "mainContactMobilePhone",
            type: "tel",
            label: "Téléphone portable du contact principal",
            required: true
        },
        {
            name: "mainContactPhone",
            type: "tel",
            label: "Téléphone fixe du contact principal",
            required: false
        },
    ];

    const handleError = (name: string) => {
        const errorMessages: { [key: string]: string[] } = {
            email: ["email must be an email"],
            password: ["password is not strong enough"],
            phone: ["phone must be a valid French phone number."],
            mobilePhone: ["mobilePhone must be a valid French phone number."],
            mainContactEmail: ["mainContactEmail must be an email"],
            mainContactFirstname: ["mainContactFirstname should not be empty", "mainContactFirstname must be a string"],
            mainContactLastname: ["mainContactLastname should not be empty", "mainContactLastname must be a string"],
            mainContactProfession: ["mainContactProfession should not be empty", "mainContactProfession must be a string"],
            mainContactPhone: ["mainContactPhone must be a valid French phone number."],
            mainContactMobilePhone: ["mainContactMobilePhone must be a valid French phone number."],
        };

        return errorMessages[name].some((message) => status.message.includes(message));
    };

    const handlePattern = (name: string) => {
        return undefined;
    };

    const handleInputChange = (event: { target: { name: any; value: any } }) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const noThanksFinal = () => () => {
        setActiveStep(3);
    }

    return (
        <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7 flex flex-row flex-wrap">
            <h4 className="w-full text-royal-900 border-b border-b-capucine-700 pb-2">Compte Entreprise</h4>
                <div className="flex flex-col">
                    <Typography variant="paragraph">Vous avez renseigné au moins 2 praticien, souhaitez-vous un compte entreprise ?</Typography>
                    <Typography variant="small">Le compte entreprise vous permet de gérer tous les praticiens du même cabinet au sein du même compte. Il n'engendre pas de frais supplémentaires.</Typography>
                
                </div>
                {formInput.map((input: any) => (
                    <div className="relative w-full md:w-1/2 p-2" key={input.name}>
                        <Input
                            autoComplete="off"
                            id={input.name}
                            name={input.name}
                            type={input.name === "password" ? isReveal ? "text" : "password" : input.type}
                            className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                            placeholder={input.label}
                            value={formData[input.name]}
                            label={input.label}
                            variant="standard"
                            color="deep-purple"
                            required={input.required}
                            minLength={input.min}
                            maxLength={input.max}
                            onChange={handleInputChange}
                            pattern={handlePattern(input.name)}
                            error={handleError(input.name)}
                        />
                        {input.name === "password" && (
                            <FontAwesomeIcon onClick={() => setIsReveal(!isReveal)} icon="eye" className="absolute top-7 right-2 text-sm text-royal-900 cursor-pointer" />
                        )}
                        {input.name === "name" && status.message.includes("name should not be empty") && (
                            <HandleErrors error="Le nom de l'entreprise ne peut pas être vide" color="red" />
                        )}
                        {input.name === "name" && status.message.includes("name must be a string") && (
                            <HandleErrors error="Le nom de l'entreprise doit être une chaîne de caractères" color="red" />
                        )}
                        {input.name === "siret" && status.message.includes("siret must be a valid SIRET number.") && (
                            <HandleErrors error="Le SIRET doit être un numéro SIRET valide" color="red" />
                        )}
                        {input.name === "email" && status.message.includes("email must be an email") && (
                            <HandleErrors error="L'adresse mail doit être une adresse mail valide" color="red" />
                        )}
                        {input.name === "phone" && status.message.includes("phone must be a valid French phone number.") && (
                            <HandleErrors error="Le numéro de téléphone doit être un numéro de téléphone français valide" color="red" />
                        )}
                        {input.name === "mobilePhone" && status.message.includes("mobilePhone must be a valid French phone number.") && (
                            <HandleErrors
                                error="Le numéro de téléphone portable doit être un numéro de téléphone portable français valide"
                                color="red"
                            />
                        )}
                        {input.name === "address" && status.message.includes("address should not be empty") && (
                            <HandleErrors error="L'adresse ne peut pas être vide" color="red" />
                        )}
                        {input.name === "address" && status.message.includes("address must be a string") && (
                            <HandleErrors error="L'adresse doit être une chaîne de caractères" color="red" />
                        )}
                        {input.name === "city" && status.message.includes("city should not be empty") && (
                            <HandleErrors error="La ville ne peut pas être vide" color="red" />
                        )}
                        {input.name === "city" && status.message.includes("city must be a string") && (
                            <HandleErrors error="La ville doit être une chaîne de caractères" color="red" />
                        )}
                        {input.name === "postalCode" && status.message.includes("postalCode should not be empty") && (
                            <HandleErrors error="Le code postal ne peut pas être vide" color="red" />
                        )}
                        {input.name === "postalCode" && status.message.includes("postalCode must be a string") && (
                            <HandleErrors error="Le code postal doit être une chaîne de caractères" color="red" />
                        )}
                        {input.name === "country" && status.message.includes("country should not be empty") && (
                            <HandleErrors error="Le pays ne peut pas être vide" color="red" />
                        )}
                        {input.name === "country" && status.message.includes("country must be a string") && (
                            <HandleErrors error="Le pays doit être une chaîne de caractères" color="red" />
                        )}
                        {input.name === "mainContactEmail" && status.message.includes("mainContactEmail must be an email") && (
                            <HandleErrors error="L'adresse mail du contact principal doit être une adresse mail valide" color="red" />
                        )}
                        {input.name === "mainContactFirstname" && status.message.includes("mainContactFirstname should not be empty") && (
                            <HandleErrors error="Le prénom du contact principal ne peut pas être vide" color="red" />
                        )}
                        {input.name === "mainContactFirstname" && status.message.includes("mainContactFirstname must be a string") && (
                            <HandleErrors error="Le prénom du contact principal doit être une chaîne de caractères" color="red" />
                        )}
                        {input.name === "mainContactLastname" && status.message.includes("mainContactLastname should not be empty") && (
                            <HandleErrors error="Le nom du contact principal ne peut pas être vide" color="red" />
                        )}
                        {input.name === "mainContactLastname" && status.message.includes("mainContactLastname must be a string") && (
                            <HandleErrors error="Le nom du contact principal doit être une chaîne de caractères" color="red" />
                        )}
                        {input.name === "mainContactProfession" && status.message.includes("mainContactProfession should not be empty") && (
                            <HandleErrors error="La profession du contact principal ne peut pas être vide" color="red" />
                        )}
                        {input.name === "mainContactProfession" && status.message.includes("mainContactProfession must be a string") && (
                            <HandleErrors error="La profession du contact principal doit être une chaîne de caractères" color="red" />
                        )}
                        {input.name === "mainContactPhone" &&
                            status.message.includes("mainContactPhone must be a valid French phone number.") && (
                                <HandleErrors
                                    error="Le numéro de téléphone du contact principal doit être un numéro de téléphone français valide"
                                    color="red"
                                />
                            )}
                        {input.name === "mainContactMobilePhone" &&
                            status.message.includes("mainContactMobilePhone must be a valid French phone number.") && (
                                <HandleErrors
                                    error="Le numéro de téléphone portable du contact principal doit être un numéro de téléphone portable français valide"
                                    color="red"
                                />
                            )}
                        {input.name === "password" && !status.message.includes("password is not strong enough") && (
                            <HandleErrors
                                error="Au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial"
                                color="gray"
                            />
                        )}
                        {input.name === "email" && !status.message.includes("email must be an email") && (
                            <HandleErrors error="Adresse mail de connexion" color="gray" />
                        )}
                        {input.name === "password" && status.message.includes("password is not strong enough") && (
                            <HandleErrors
                                error="Au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial"
                                color="gray"
                            />
                        )}
                    </div>
                ))}
                <div className="w-full flex justify-center mt-4 gap-4">
                    <Button type="button" onClick={noThanksFinal()} color="lime" className="text-white">
                        Non merci
                    </Button>
                </div>
        </div>
    );
}
