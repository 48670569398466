import { Dialog } from "@headlessui/react";

import ModalGenerique from "./ModalGenerique";

import EmployeeModal from "./Employee";
import PatientModal from "./Patient";
import InvalidModal from "./Invalid";
import VerifClient from "./VerifClient";

import { toast } from "react-toastify";
import EndModal from "./EndModal";

export default function ModalGlobal({
    open,
    setOpen,
    type,
    data,
    maj,
}: Readonly<{
    open: boolean;
    setOpen: (open: string, arg0?: boolean) => void;
    type: string;
    data: any;
    maj: () => void;
}>) {
    const handleStatus = (state: string, msg: string) => {
        if (state === "error") toast.error(msg);
        else if (state === "success") {
            toast.success(msg);
            maj();
        } else if (state === "already") toast.error(msg);
        else if (state === "complete") toast.warning(msg);
        else console.log("error");
    };

    return (
        <ModalGenerique open={open} setOpen={setOpen} type={type}>
            <Dialog.Panel className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ${(type === "working" || type === "finished") ? "sm:w-full" : "sm:w-auto"} sm:mx-8`}>
                {type === "employee" && <EmployeeModal setOpen={setOpen} data={data} handleStatus={handleStatus} />}
                {type === "patient" && <PatientModal setOpen={setOpen} data={data} handleStatus={handleStatus} />}
                {type === "invalid" && <InvalidModal setOpen={setOpen} data={data} />}
                {type === "working" && <VerifClient setOpen={setOpen} data={data} handleStatus={handleStatus} />}
                {type === "finished" && <EndModal setOpen={setOpen} data={data} handleStatus={handleStatus} />}
            </Dialog.Panel>
        </ModalGenerique>
    );
}
