export const steps = [
    {
        target: "body",
        content: "Bienvenue dans la section des factures. Vous pouvez les consulter et les régler ici.",
        placement: "center" as const,
    },
    {
        target: ".allTable-step",
        content: "Voici les détails de chaque facture : l'identifiant unique, le titre, le praticien et son cabinet, la date, et la mention 'TVA' indiquant sa soumission à la TVA.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    {
        target: ".allTable-step",
        content: "Le bouton indique l'état de la facture : vert pour payée, rouge pour non payée (cliquez pour payer), et orange pour en cours de traitement.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    {
        target: ".consultInvoice-step",
        content: "Pour consulter ou télécharger une facture, cliquez simplement sur ce bouton.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
];


export const facticeOrder = [
    {
        id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        code: 1,
        title: "TEST-0001",
        hasVat: true,
        items: [
            {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                code: "TEST-0001",
                quantity: 1,
                name: "Test",
                description: "Test",
                price: 100
            }
        ],
        payment: {
            id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
            paymentStatus: "PAID",
            practicerId: new Date(),
            price: 100
        },
        practicer: {
            id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
            civility: "M.",
            fullName: "Jean Dupont",
            isCustom: false,
            company: {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                name: "Cabinet Dupont",
                address: "Test",
                postalCode: "Test",
                city: "Test",
                country: "Test",
                isCustom: false
            
            }
        },
        assignedLab: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        date: new Date(),
        draft: false
    },
    {
        id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        code: 2,
        title: "TEST-0002",
        hasVat: false,
        items: [
            {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                code: "TEST-0002",
                quantity: 1,
                name: "Test",
                description: "Test",
                price: 100
            }
        ],
        payment: {
            id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
            paymentStatus: "UNPAID",
            practicerId: new Date(),
            price: 100
        },
        practicer: {
            id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
            civility: "M.",
            fullName: "Jean Dupont",
            isCustom: false,
            company: {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                name: "Cabinet Dupont",
                address: "Test",
                postalCode: "Test",
                city: "Test",
                country: "Test",
                isCustom: false
            
            }
        },
        assignedLab: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        date: new Date(),
        draft: false
    },
    {
        id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        code: 3,
        title: "TEST-0003",
        hasVat: false,
        items: [
            {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                code: "TEST-0003",
                quantity: 1,
                name: "Test",
                description: "Test",
                price: 100
            }
        ],
        payment: {
            id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
            paymentStatus: "PENDING",
            practicerId: new Date(),
            price: 100
        },
        practicer: {
            id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
            civility: "M.",
            fullName: "Jean Dupont",
            isCustom: false,
            company: {
                id: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
                name: "Cabinet Dupont",
                address: "Test",
                postalCode: "Test",
                city: "Test",
                country: "Test",
                isCustom: false
            
            }
        },
        assignedLab: "717ec36a-2346-40ff-b43d-e7eb76a170e6",
        date: new Date(),
        draft: false
    }
]
