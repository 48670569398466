import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import FacturationApi from "../../services/API/clients/FacturationAPI";
import { Card, CardBody, CardHeader, Typography } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function Loader() {
    return (
        <div className="flex justify-center">
            <FontAwesomeIcon icon="spinner" className="animate-spin" />
        </div>
    )
}

interface PDFResponse {
    loading: boolean;
    pdf: string | null;
}

export default function InvoiceDocument() {
    const { id } = useParams<{ id: string }>()

    const [pdfResponse, setPdfResponse] = useState<PDFResponse>({
        loading: false,
        pdf: null
    });

    useEffect(() => {
        if (id) {
            FacturationApi.getInvoiceDocument(id)
                .then((response) => {
                    if (response.status === 200)
                        setPdfResponse({
                            loading: false,
                            pdf: response.body.pdf
                        });
                    else
                        setPdfResponse({
                            loading: false,
                            pdf: null
                        });
                })
                .catch((error) => {
                    console.error(error);
                    setPdfResponse({
                        loading: false,
                        pdf: null
                    });
                })
            setPdfResponse({
                loading: true,
                pdf: null
            });
        }
    }, [id]);

    return (
        <div className="mt-12 mb-8 flex flex-col gap-12">
            <Card>
                <CardHeader
                    variant="gradient"
                    color={"deep-purple"}
                    className="mb-4 p-4 flex"
                >
                    <Typography variant="h6" color="white">
                        Facturation
                    </Typography>
                </CardHeader>
                <CardBody>
                    {pdfResponse.loading && <Loader />}
                    {pdfResponse.pdf && <iframe title="facture" src={`data:application/pdf;base64,${pdfResponse.pdf}`} width="100%" height="1000px" />}
                </CardBody>
            </Card>
        </div>)
}