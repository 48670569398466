import { Badge, Button, Typography } from "@material-tailwind/react";
import React, { ReactNode, useEffect } from "react";
import { NavLink } from "react-router-dom";

interface NavLinksProps {
    layout?: string;
    path: string;
    handleOpen: () => void;
    icon: ReactNode;
    name: string;
    notifications: any[];
    fetchData: () => void;
    tutorials: any[];
    fetchTutorials: () => void;
}

const NavLinks: React.FC<NavLinksProps> = ({ layout, path, handleOpen, icon, name, notifications, fetchData, tutorials, fetchTutorials }) => {
    const [display, setDisplay] = React.useState(false);
    const [width, setWidth] = React.useState(window.innerWidth);
    
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    React.useEffect(() => {
        switch (name) {
            case "Commande":
                setDisplay(false);
                break;
            case "En attente":
                setDisplay(
                    notifications.some(
                        (item) =>
                            item.action === "refuseWaiting" || item.action === "updateInvalid" || item.action === "update" || item.action === "reportError"
                    )
                );
                break;
            case "En cours":
                setDisplay(
                    notifications.some(
                        (item) =>
                            item.action === "reassignLaboratory" ||
                            item.action === "reassignEmployee" ||
                            item.action === "reassignTask" ||
                            item.action === "acceptWaiting" ||
                            item.action === "assignEmployee" ||
                            item.action === "askForClientVerification" ||
                            item.action === "acceptModelByClient" ||
                            item.action === "refuseModelByClient" ||
                            item.action === "finishModeling" ||
                            item.action === "finishProduction" ||
                            item.action === "finishFinishing" ||
                            item.action === "accept" ||
                            item.action === "refuse"
                    )
                );
                break;
            case "Terminées":
                setDisplay(notifications.some((item) => item.action === "cancel" || item.action === "send"));
                break;
            case "Entreprise":
                setDisplay(false);
                break;
            case "Patient":
                setDisplay(false);
                break;
            case "Facturation":
                setDisplay(
                    notifications.some(
                        (item) =>
                            item.action === "notifyInvoice" ||
                            item.action === "notifyInvoicePaymentSuccess" ||
                            item.action === "notifyInvoiceInsufficientAmount"
                    )
                );
                break;
            default:
                setDisplay(false);
                break;
        }
    }, [notifications, name]);

    const NameOfClass = (name: string) => {
        switch (name) {
            case "Commande":
                return "commande_step";
            case "En attente":
                return "wait_step";
            case "En cours":
                return "in_progress_step";
            case "Terminées":
                return "done_step";
            case "Entreprise":
                return "company_step";
            case "Patient":
                return "patient_step";
            case "Facturation":
                return "facturation_step";
            default:
                return "hidden";
        }
    };

    const doIShowIt = () => {
        if (layout === undefined) {
            return;
        }
        const currentPage = `/${layout}${path}`;
        const currentTutorial = tutorials.find((item) => item.page === currentPage);
        if (currentTutorial !== undefined) {
            if (currentTutorial.visited) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    return (
        <NavLink
            to={`/${layout}${path}`}
            onClick={() => {
                handleOpen();
                fetchData();
                fetchTutorials();
            }}
        >
            {({ isActive }) => {
                return (
                    <Button
                        variant={isActive ? "gradient" : "text"}
                        color={isActive ? "deep-purple" : "blue-gray"}
                        className={`flex items-center gap-6 px-4 capitalize ${NameOfClass(name)}`}
                        fullWidth
                    >
                        {display ? (
                            <>
                                <Badge className="animate-pulse" color="lime" withBorder>
                                    {icon}
                                </Badge>
                                {doIShowIt() && !isActive && width > 768 ? (
                                    <button
                                        className="react-joyride__beacon"
                                        data-test-id="button-beacon"
                                        style={{
                                            backgroundColor: "transparent",
                                            border: "0px",
                                            borderRadius: "0px",
                                            color: "rgb(85, 85, 85)",
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            lineHeight: 1,
                                            padding: "4px",
                                            appearance: "none",
                                            display: "inline-block",
                                            height: "24px",
                                            position: "relative",
                                            width: "24px",
                                            zIndex: 10000,
                                            marginLeft: "5px",
                                        }}
                                        type="button"
                                        aria-label="Open the dialog"
                                        title="Open the dialog"
                                    >
                                        <span
                                            style={{
                                                animation: "1.2s ease-in-out 0s infinite normal none running joyride-beacon-inner",
                                                backgroundColor: "rgb(255, 0, 68)",
                                                borderRadius: "50%",
                                                display: "block",
                                                height: "50%",
                                                left: "50%",
                                                opacity: 0.7,
                                                position: "absolute",
                                                top: "50%",
                                                transform: "translate(-50%, -50%)",
                                                width: "50%",
                                            }}
                                        ></span>
                                        <span
                                            style={{
                                                animation: "1.2s ease-in-out 0s infinite normal none running joyride-beacon-outer",
                                                backgroundColor: "rgba(255, 0, 68, 0.2)",
                                                border: "2px solid rgb(255, 0, 68)",
                                                borderRadius: "50%",
                                                boxSizing: "border-box",
                                                display: "block",
                                                height: "100%",
                                                left: "0px",
                                                opacity: 0.9,
                                                position: "absolute",
                                                top: "0px",
                                                transformOrigin: "center center 0px",
                                                width: "100%",
                                            }}
                                        ></span>
                                    </button>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <div className="flex gap-1 items-center">
                                {icon}{" "}
                                {doIShowIt() && !isActive && width > 768 ? (
                                    <button
                                        className="react-joyride__beacon"
                                        data-test-id="button-beacon"
                                        style={{
                                            backgroundColor: "transparent",
                                            border: "0px",
                                            borderRadius: "0px",
                                            color: "rgb(85, 85, 85)",
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            lineHeight: 1,
                                            padding: "4px",
                                            appearance: "none",
                                            display: "inline-block",
                                            height: "24px",
                                            position: "relative",
                                            width: "24px",
                                            zIndex: 10000,
                                            marginLeft: "5px",
                                        }}
                                        type="button"
                                        aria-label="Open the dialog"
                                        title="Open the dialog"
                                    >
                                        <span
                                            style={{
                                                animation: "1.2s ease-in-out 0s infinite normal none running joyride-beacon-inner",
                                                backgroundColor: "rgb(255, 0, 68)",
                                                borderRadius: "50%",
                                                display: "block",
                                                height: "50%",
                                                left: "50%",
                                                opacity: 0.7,
                                                position: "absolute",
                                                top: "50%",
                                                transform: "translate(-50%, -50%)",
                                                width: "50%",
                                            }}
                                        ></span>
                                        <span
                                            style={{
                                                animation: "1.2s ease-in-out 0s infinite normal none running joyride-beacon-outer",
                                                backgroundColor: "rgba(255, 0, 68, 0.2)",
                                                border: "2px solid rgb(255, 0, 68)",
                                                borderRadius: "50%",
                                                boxSizing: "border-box",
                                                display: "block",
                                                height: "100%",
                                                left: "0px",
                                                opacity: 0.9,
                                                position: "absolute",
                                                top: "0px",
                                                transformOrigin: "center center 0px",
                                                width: "100%",
                                            }}
                                        ></span>
                                    </button>
                                ) : (
                                    <></>
                                )}
                            </div>
                        )}
                        <Typography color="inherit" className="font-medium capitalize">
                            {name}
                        </Typography>
                    </Button>
                );
            }}
        </NavLink>
    );
};

export default NavLinks;
