import React from "react";
import { Typography } from "@material-tailwind/react";

interface Props {
    children?: React.ReactNode;
    header: any;
}

const TableWrapper: React.FC<Props> = ({ children, header }) => {
    return (
        <table className="w-full table-auto">
            <thead>
                <tr>
                    {header.map((column: any) => (
                        <th key={column.col} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                            <Typography variant="small" className="text-[11px] font-bold uppercase text-blue-gray-400">
                                {column.id}
                            </Typography>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </table>
    );
};

export default TableWrapper;
