import React, { useRef } from "react";
import { Dialog } from "@headlessui/react";
import "tw-elements";
import { Input } from "@material-tailwind/react";
import CompanyAPI from "../../services/API/clients/CompanyAPI";
import PatientAPI from "../../services/API/clients/PatientAPI";

import { toast } from "react-toastify";

import PraticerAPI from "../../services/API/clients/PraticerAPI";
import ModalGenerique from "../../components/ModalGenerique";
import HandleErrors from "../../components/HandleErrors";

import Select from "react-select";
import { Storage } from "../../services/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const civilites = [
    { label: "Monsieur", value: "MAN" },
    { label: "Madame", value: "WOMAN" },
    { label: "Autre", value: "OTHER" },
];

export default function ModalPatient({ open, setOpen, maj }: Readonly<{ open: boolean; setOpen: () => void; maj: () => void }>) {
    const cancelButtonRef = useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [status, setStatus] = React.useState<{ message: string[] }>({
        message: [],
    });

    const [patient, setPatient] = React.useState({
        lastname: "",
        firstname: "",
        civility: "OTHER",
    });

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        const companyID = Storage.getCompanyId();
        // if (Storage.getRoles().includes("COMPANY")) {
        //     const resp = await CompanyAPI.company();
        //     if (resp.status === 200) companyID = resp.body.id;
        // } else {
        //     const resp = await PraticerAPI.getPraticer();
        //     if (resp.status === 200) companyID = resp.body.companyId;
        // }
        const data = {
            lastname: patient.lastname,
            firstname: patient.firstname,
            civility: patient.civility,
            companyId: companyID,
        };

        const response = await PatientAPI.newPatient(data);
        const { body } = response;
        if (response.status === 201) {
            toast.success("Le patient a bien été créé");
            maj();
        } else if (response.status === 400) {
            toast.warning("Veuillez vérifier que tous les champs ont bien été complété");
            setStatus({ message: body.error.response.message });
        } else if (response.status === 409) {
            toast.error("Ce patient existe déjà");
            setStatus({ message: body.error.response.message });
        } else {
            toast.error("Une erreur est survenue lors de la création du patient");
        }
        setLoading(false);
    };

    const handleInputChange = (event: { target: { name: any; value: any } }) => {
        const { name, value } = event.target;
        setPatient({ ...patient, [name]: value });
    };

    const customStyles = {
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        control: (baseStyles: any, state: { isFocused: any }) => ({
            ...baseStyles,
            boxShadow: state.isFocused ? "0 0 0 1px rgb(0, 51, 107)" : "",
            borderColor: state.isFocused ? "rgb(0, 51, 107)" : "rgb(176, 190, 197)",
            backgroundColor: "transparent",
            "&:hover": {
                borderColor: state.isFocused ? "rgb(0, 51, 107)" : "rgb(176, 190, 197)",
            },
        }),
    };

    const handleSelectChange = (event: any) => {
        if (event !== undefined) {
            setPatient({ ...patient, civility: event.value });
        }
    };

    return (
        <ModalGenerique open={open} setOpen={setOpen}>
            <form onSubmit={handleSubmit}>
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg allModal-step">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 border-b pb-2">
                                    Nouveau Patient
                                </Dialog.Title>
                                <div className="mt-4 flex flex-col justify-center">
                                    <div className="flex flex-col md:flex-row gap-4">
                                        {[
                                            {
                                                name: "lastname",
                                                label: "Nom",
                                            },
                                            {
                                                name: "firstname",
                                                label: "Prénom",
                                            },
                                        ].map((input) => (
                                            <div key={input.name}>
                                                <Input
                                                    type="text"
                                                    color="deep-purple"
                                                    name={input.name}
                                                    id={input.name}
                                                    onChange={handleInputChange}
                                                    label={input.label}
                                                    error={
                                                        (input.name === "firstname" && status.message.includes("firstname should not be empty")) ||
                                                        (input.name === "lastname" && status.message.includes("lastname should not be empty"))
                                                    }
                                                    required
                                                />
                                                {input.name === "firstname" && status.message.includes("firstname should not be empty") && (
                                                    <HandleErrors error="Le prénom ne peut pas être vide" color="red" />
                                                )}
                                                {input.name === "lastname" && status.message.includes("lastname should not be empty") && (
                                                    <HandleErrors error="Le nom ne peut pas être vide" color="red" />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex flex-row gap-4 mt-2">
                                        <Select
                                            placeholder="Civilité"
                                            name="roles"
                                            className="w-full"
                                            options={civilites}
                                            onChange={(event) => handleSelectChange(event)}
                                            menuPortalTarget={document.body}
                                            styles={customStyles}
                                            value={civilites.filter((civilite) => patient.civility === civilite.value)}
                                            menuPosition="fixed"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                            type="submit"
                            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto validateFormNew-step"
                        >
                            {loading ? <FontAwesomeIcon icon="spinner" spin /> : "Créer"}
                        </button>
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={() => setOpen()}
                            ref={cancelButtonRef}
                        >
                            Annuler
                        </button>
                    </div>
                </Dialog.Panel>
            </form>
        </ModalGenerique>
    );
}
