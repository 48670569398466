import React from "react";
import { Typography } from "@material-tailwind/react";
import Pagination from "./Pagination";
import ModalGlobal from "./Modals/ModalGlobal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
    header: any;
    data: any;
    from: string;
    maj: any;
    edit?: boolean;
    currentPage: number;
    setCurrentPage: any;
}

const Table: React.FC<Props> = ({ header, data, from, maj, edit = true, currentPage, setCurrentPage }) => {
    const itemsPerPage = 50;

    const totalItems = data.length;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems - 1);
    const visibleItems = data.slice(startIndex, endIndex + 1);
    const [myObjectEdit, setMyObjectEdit] = React.useState<any>({});
    const [open, setOpen] = React.useState(false);

    const handleOpen = (_value: string) => {
        setOpen(!open);
    };

    const handleOpenElement = (element: any) => {
        setMyObjectEdit(element);
        setOpen(!open);
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <>
            <ModalGlobal open={open} setOpen={handleOpen} type={from} data={myObjectEdit} maj={maj} />
            <div className="table-container">
                <table className="w-full table-auto overflow-auto">
                    <thead>
                        <tr>
                            {header.map((column: any, i: any) => (
                                <th key={column.col} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                    <Typography variant="small" className="text-[11px] font-bold uppercase text-blue-gray-400">
                                        {column.id}
                                    </Typography>
                                </th>
                            ))}
                            <th className="border-b border-blue-gray-50 py-3 px-5 text-left"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data !== undefined &&
                            visibleItems.map((element: any, key: number) => {
                                const className = `py-3 px-5 ${key === visibleItems.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                                return (
                                    <React.Fragment key={element.id + 1}>
                                        <tr className={`example-element-row`}>
                                            {header.map((column: any, i: number) => (
                                                <td key={column.id} className={className}>
                                                    {column.col === "civility" ? (
                                                        <>
                                                            {element[column.col] === "MAN" ? (
                                                                <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-blue-200 text-blue-700 rounded-full">
                                                                    Monsieur
                                                                </div>
                                                            ) : element[column.col] === "WOMAN" ? (
                                                                <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-pink-200 text-pink-700 rounded-full">
                                                                    Madame
                                                                </div>
                                                            ) : (
                                                                <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-yellow-200 text-yellow-700 rounded-full">
                                                                    Autre
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <Typography variant="small" color="blue-gray" className={i === 0 ? "font-bold" : ""}>
                                                            {element[column.col]}
                                                        </Typography>
                                                    )}
                                                </td>
                                            ))}
                                            {edit && (
                                                <td className={`${className} w-8`} onClick={() => handleOpenElement(element)}>
                                                    <FontAwesomeIcon icon="pen-to-square" className="cursor-pointer" onClick={() => handleOpenElement(element)} />
                                                </td>
                                            )}
                                        </tr>
                                    </React.Fragment>
                                );
                            })}
                    </tbody>
                </table>
            </div>

            <Pagination
                handlePageChange={handlePageChange}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                totalItems={totalItems}
                startIndex={startIndex}
                endIndex={endIndex}
            />
        </>
    );
};

export default Table;
