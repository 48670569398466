export const steps = [
    { // 1
        target: "body",
        content: "Bienvenue sur la plateforme de commande de Lio pour vos patients ! Vous êtes sur la page de création de commande. Suivez les étapes ci-dessous pour créer votre commande.",
        placement: "center" as const,
    },
    { // 3
        target: ".second-step",
        content: "Sélectionnez le praticien de la commande ici. Choisissez s'il s'agit d'un patient existant ou à créer. Pour un nouveau patient, cliquez sur 'Nouveau Patient' et remplissez le formulaire.",
        placementBeacon: "left" as const,
        placement: "auto" as const,
    },
    { // 7
        target: ".fifth-step",
        content: "Ajoutez des commentaires sur la commande dans cet espace.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    { // 8
        target: ".sixth-step",
        content: "Déposez des fichiers pour la commande dans cet encart. Vous pouvez en ajouter plusieurs de tout type.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    { // 9
        target: ".seventh-step",
        content: "Définissez la date d'échéance de la commande ici. Notez qu'une majoration peut s'appliquer selon la date choisie.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    { // 10
        target: ".eighth-step",
        content: "Enfin, une fois la commande complète, cliquez sur ce bouton pour la passer.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
    { // 11
        target: ".wait_step",
        content: "Cliquez ici pour passer à l'étape suivante.",
        placementBeacon: "left" as const,
        placement: "left" as const,
    },
];