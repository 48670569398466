import React, { useEffect, useRef } from "react";
import { Dialog } from "@headlessui/react";
import { Input } from "@material-tailwind/react";

export default function ModalPatient({ setOpen, data, handleStatus }: Readonly<{ setOpen: any; data: any; handleStatus: any }>) {
    const cancelButtonRef = useRef(null);
    const [actualUser, setActualUser] = React.useState({
        lastname: data.lastname,
        firstname: data.firstname,
        profession: data.profession,
        email: data.email,
        phone: data.phone,
        mobilePhone: data.mobilePhone,
    });

    useEffect(() => {
        setActualUser({
            lastname: data.lastname,
            firstname: data.firstname,
            profession: data.profession,
            email: data.email,
            phone: data.phone,
            mobilePhone: data.mobilePhone,
        });
    }, [data]);

    return (
        <form>
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 border-b pb-2">
                            Edition de {data.firstname} {data.lastname}
                        </Dialog.Title>
                        <div className="mt-4 flex flex-col justify-center">
                            <div className="flex flex-row gap-4">
                                <Input
                                    type="text"
                                    color="deep-purple"
                                    name="lastname"
                                    id="lastname"
                                    value={actualUser.lastname}
                                    label="Nom"
                                />
                                <Input
                                    type="text"
                                    color="deep-purple"
                                    name="firstname"
                                    id="firstname"
                                    value={actualUser.firstname}
                                    label="Prénom"
                                />
                            </div>
                            <div className="flex flex-row gap-4 mt-2">
                                <Input
                                    type="text"
                                    color="deep-purple"
                                    name="profession"
                                    id="profession"
                                    value={actualUser.profession}
                                    label="Profession"
                                />
                                <Input
                                    type="email"
                                    color="deep-purple"
                                    name="email"
                                    id="email"
                                    value={actualUser.email}
                                    label="Adresse mail"
                                />
                            </div>
                            <div className="flex flex-row gap-4 mt-2">
                                <Input
                                    type="tel"
                                    color="deep-purple"
                                    name="phone"
                                    id="phone"
                                    min={10}
                                    max={10}
                                    value={actualUser.phone}
                                    label="Fixe"
                                />
                                <Input
                                    type="tel"
                                    color="deep-purple"
                                    name="mobilePhone"
                                    id="mobilePhone"
                                    min={10}
                                    max={10}
                                    value={actualUser.mobilePhone}
                                    label="Mobile"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto modifCollaborateur-step"
                >
                    Modifier
                </button>
                <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto deleteCollaborateur-step"
                >
                    Supprimer
                </button>
                <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen()}
                    ref={cancelButtonRef}
                >
                    Fermer
                </button>
            </div>
        </form>
    );
}
