import React, { useState } from "react";

import { Button, Typography } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import Pagination from "./Pagination";
import OrderAPI from "../services/API/clients/OrderAPI";
import ModalGlobalTutorial from "./Modals/Tutorials/ModalGlobal";

interface Props {
    header: any;
    data: any;
    headerExtends: any;
    from: string;
    maj: any;
    notifications?: any[];
    open: boolean;
    setOpen: any;
    myObjectEdit: any;
    setMyObjectEdit: any;
}

const itemsPerPage = 25;

const TableExtendTutorial: React.FC<Props> = ({ header, data, headerExtends, from, maj, notifications, open, setOpen, myObjectEdit, setMyObjectEdit }) => {
    const [expandedElement, setExpandedElement] = useState<[] | null>(null);

    const [currentPage, setCurrentPage] = React.useState(1);
    const totalItems = data.length;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems - 1);
    const visibleItems = data.slice(startIndex, endIndex + 1);

    const handleSwitchToTnt = async (id: string) => {
        const response = await OrderAPI.trackingUrl(id);
        if (response.status === 200 || response.status === 201) {
            const url = response.body.url
            window.open(url, '_blank')
        }
    };

    const handleOpen = (_value: string) => {
        setOpen(!open);
        maj();
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleChooseColorText = (arg: string) => {
        switch (arg) {
            case "WAITING":
                return <div className="waitPastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-cyan-100 rounded-full">Attente</div>;
            case "VALID":
                return <div className="validPastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-orange-100 rounded-full">Modélisation</div>;
            case "MODELING":
                return <div className="modelPastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-orange-100 rounded-full">Modélisation</div>;
            case "LAB_CHIEF_VERIFICATION":
                return <div className="labChiefPastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-orange-100 rounded-full">Modélisation</div>;
            case "LAB_CHIEF_VERIFIED":
                return <div className="labChiefVerifiedPastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-orange-100 rounded-full">Modélisation</div>;
            case "CLIENT_VERIFICATION":
                return (
                    <div className="clientVerifPastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-indigo-100 rounded-full">
                        En attente de vérification
                    </div>
                );
            case "CLIENT_VERIFIED":
                return <div className="clientVerifiedPastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-orange-100 rounded-full">Modélisation</div>;
            case "MODELING_DONE":
                return <div className="modelDonePastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-orange-100 rounded-full">Production</div>;
            case "PRODUCTION":
                return <div className="prodPastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-blue-100 rounded-full">Production</div>;
            case "PRODUCTION_DONE":
                return <div className="prodDonePastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-blue-100 rounded-full">Production</div>;
            case "FINISHING":
                return <div className="finishingPastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-purple-100 rounded-full">Finition</div>;
            case "FINISHING_DONE":
                return <div className="finishDonePastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-purple-100 rounded-full">Finition</div>;
            case "VERIFICATION":
                return (
                    <div className="verifPastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-pink-100 rounded-full">Attente d'envoi</div>
                );
            case "VERIFIED":
                return (
                    <div className="verifiedPastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-pink-100 rounded-full">Attente d'envoi </div>
                );
            case "SENT":
                return <div className="sentPastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-amber-100 rounded-full">Envoyée</div>;
            case "INVALID":
                return <div className="invalidPastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-red-100 rounded-full">Invalide</div>;
            case "CANCELED":
                return <div className="canceledPastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-gray-100 rounded-full">Annulée</div>;
            case "WAITING_INSTRUCTION":
                return <div className="waitInstrucPastille-step text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-cyan-100 rounded-full">En cours</div>;
            default:
                return;
        }
    };

    const renderedColumn = (value: any, isTeethColumn: boolean, isId: boolean, element: { code: string }, index: number, key: number) => {
        if (isTeethColumn) return value.join(", ");
        else if (isId) return `${element.code}-${index}`;
        // else if (isId)
        //     return `00000${key}`
        else return value;
    };

    const ExpandedPart = (element: any, className: string, key: number) => {
        if (expandedElement === element)
            return (
                <tr className="border-b border-blue-gray-50">
                    <td colSpan={header.length} className={className}>
                        <table className="w-full table-auto overflow-x-auto">
                            <thead>
                                <tr>
                                    {headerExtends.map((column: any) => (
                                        <th key={column.id} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                            <Typography variant="small" className="text-[11px] font-bold uppercase text-blue-gray-400">
                                                {column.id}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {element.products.map((product: any, index: number) => {
                                    return (
                                        <tr key={product.id}>
                                            {headerExtends.map((column: any, i: number) => {
                                                const columnKeys = column.col.split(".");
                                                const value = columnKeys.reduce((obj: any, key: any) => obj && obj[key], product);
                                                const isTeethColumn = column.col === "teeth";
                                                const isId = column.col === "code";
                                                return (
                                                    <td key={column.col + product.id} className={className}>
                                                        <Typography variant="small" color="blue-gray" className={i === 0 ? "font-bold" : ""}>
                                                            {renderedColumn(value, isTeethColumn, isId, element, index, key)}
                                                        </Typography>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </td>
                </tr>
            );
    };

    const toNormal = (element: any, className: string, key: number) => {
        return (
            <>
                {header.map((column: any, i: number) => (
                    <td
                        key={column.id}
                        className={className}
                        onClick={() => setExpandedElement((prevExpandedElement: any) => (prevExpandedElement === element ? null : element))}
                    >
                        {overStep(column, element, i)}
                    </td>
                ))}
            </>
        );
    };

    const overStep = (column: any, element: any, i: number) => {
        return (
            <>
                {column.col === "step" ? (
                    handleChooseColorText(element[column.col])
                ) : column.col === "patientFullName" ? (
                    <Typography variant="small" color="blue-gray" className={i === 0 ? "font-bold patient-step" : "patient-step"}>
                        {element[column.col]}
                    </Typography>
                ) : column.col === "practicerFullName" ? (
                    <Typography variant="small" color="blue-gray" className={i === 0 ? "font-bold praticien-step" : "praticien-step"}>
                        {element[column.col]}
                    </Typography>
                ) : column.col === "code" ? (
                    <Typography variant="small" color="blue-gray" className={i === 0 ? "font-bold id-step" : " id-step"}>
                        {element[column.col]}
                    </Typography>
                ) : (
                    <Typography variant="small" color="blue-gray" className={i === 0 ? "font-bold" : ""}>
                        {element[column.col]}
                    </Typography>
                )}
            </>
        );
    };

    const toEdit = (element: any, className: string) => {
        return (
            <>
                {(element.step === "SENT" &&
                    <td className={`${className} w-8`}>
                        <Button variant="outlined" color="blue" onClick={() => handleSwitchToTnt(element.id)} className="mx-1 text-xs py-2 px-4 whitespace-nowrap suiviColis-step" disabled={!element.travelVoucher}>
                            suivi colis
                        </Button>
                    </td>
                )}
                {(element.step === "CANCELED" &&
                    <td className={`${className} w-8`}>
                    </td>
                )}
                <td className={`${className} w-8 editPart-step ${element.step === "INVALID" && "invaliding-step"}`}>
                    <FontAwesomeIcon icon="pen-to-square" className="cursor-pointer" />
                </td>
                <td className={`${className} w-8`}>
                    <a href={`/dash/chat/${element.id}`}>
                        <ChatBubbleLeftIcon className="h-5 w-5 text-capucine-700 cursor-pointer" />
                    </a>
                </td>
            </>
        );
    };

    return (
        <>
            <ModalGlobalTutorial open={open} setOpen={handleOpen} type={from} data={myObjectEdit} maj={maj} />
            <div className="table-container">
                <table className="w-full table-auto overflow-auto">
                    <thead>
                        <tr>
                            {header.map((column: any) => (
                                <th key={column.col} className={`border-b border-blue-gray-50 py-3 px-5 text-left`}>
                                    <Typography variant="small" className="text-[11px] font-bold uppercase text-blue-gray-400">
                                        {column.id}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                        <tr></tr>
                    </thead>
                    <tbody>
                        {visibleItems !== undefined &&
                            visibleItems.map((element: any, key: number) => {
                                const className = `py-3 px-5 ${key === visibleItems.length - 1 ? "" : expandedElement === element ? "" : "border-b border-blue-gray-50"}`;

                                return (
                                    <React.Fragment key={element.id}>
                                        <tr className={`tr-step example-element-row ${expandedElement === element ? "example-expanded-row" : ""}`}>
                                            {toNormal(element, className, key)}
                                            {toEdit(element, className)}
                                        </tr>
                                        {ExpandedPart(element, className, key)}
                                    </React.Fragment>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            <Pagination
                handlePageChange={handlePageChange}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                totalItems={totalItems}
                startIndex={startIndex}
                endIndex={endIndex}
            />
        </>
    );
};

export default TableExtendTutorial;
