import React from "react";
import classNames from "classnames";
import moment from "moment";

interface CalendarDayProps {
    loading: boolean;
    date: Date | null;
    color: string;
    onClick: () => void;
}

const CalendarDay: React.FC<CalendarDayProps> = ({ loading, date, color, onClick }) => {
    const dayClasses = classNames(
        "p-0.5 pb-3 h-8 text-xs md:text-sm flex items-start justify-start relative bg-white border",
        {
            "cursor-pointer": color !== "UNAVAILABLE",
        },
        {
            "cursor-not-allowed": color === "UNAVAILABLE",
        },
        {
            "border-red-200": color === "HIGH_PRIORITY",
        },
        {
            "border-orange-200": color === "MEDIUM_PRIORITY",
        },
        {
            "border-green-200": color === "LOW_PRIORITY",
        },
        {
            "border-gray-200": color === "UNAVAILABLE",
        },
        {
            "border-gray-500": loading,
        }
    );

    const borderClasses = classNames(
        "h-0 w-0 border-b-[30px] border-l-[36px] border-l-transparent absolute bottom-0 right-0", 
        {
            "border-b-red-200": color === "HIGH_PRIORITY",
        },
        {
            "border-b-orange-200": color === "MEDIUM_PRIORITY",
        },
        {
            "border-b-green-200": color === "LOW_PRIORITY",
        },
        {
            "border-b-gray-200": color === "UNAVAILABLE",
        },
        {
            "border-b-gray-500": loading,
        }
    );

    return (
        <button type="button" className={dayClasses} onClick={onClick}>
            <span>{date && moment.utc(date).format("DD")}</span>
            <div className={borderClasses}></div>
        </button>
    );
};

export default CalendarDay;
